export default [
  {
    path: 'drgallery',
    name: 'drgallery',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'drgallery.list',
        meta: {
          permission: ['Drgallery List', 'Drgallery List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/drgallery/list.vue'),
      },
      {
        path: 'create',
        name: 'drgallery.create',
        meta: {
          permission: 'Create Drgallery',
        },
        component: () => import('@/views/dashboard/gtrans/drgallery/create.vue'),
      },
      {
        path: 'edit/:drgallery',
        name: 'drgallery.edit',
        meta: {
          permission: ['Update Drgallery', 'Update Drgallery Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/drgallery/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'drgallery.trashed',
        meta: {
          permission: ['Show Drgallery Trash', 'Show Drgallery Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/drgallery/trashed.vue'),
      },
    ],
  },
]
