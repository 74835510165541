export default [
  {
    path: 'cimporticon',
    name: 'cimporticon',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'cimporticon.list',
        meta: {
          permission: ['Cimporticon List', 'Cimporticon List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/cimporticon/list.vue'),
      },
      {
        path: 'create',
        name: 'cimporticon.create',
        meta: {
          permission: 'Create Cimporticon',
        },
        component: () => import('@/views/dashboard/gtrans/cimporticon/create.vue'),
      },
      {
        path: 'edit/:cimporticon',
        name: 'cimporticon.edit',
        meta: {
          permission: ['Update Cimporticon', 'Update Cimporticon Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/cimporticon/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'cimporticon.trashed',
        meta: {
          permission: ['Show Cimporticon Trash', 'Show Cimporticon Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/cimporticon/trashed.vue'),
      },
    ],
  },
]
