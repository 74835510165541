export default [
  {
    path: 'videojsr',
    name: 'videojsr',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'videojsr.list',
        meta: {
          permission: ['Videojsr List', 'Videojsr List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videojsr/list.vue'),
      },
      {
        path: 'create',
        name: 'videojsr.create',
        meta: {
          permission: 'Create Videojsr',
        },
        component: () => import('@/views/dashboard/gtrans/videojsr/create.vue'),
      },
      {
        path: 'edit/:videojsr',
        name: 'videojsr.edit',
        meta: {
          permission: ['Update Videojsr', 'Update Videojsr Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videojsr/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'videojsr.trashed',
        meta: {
          permission: ['Show Videojsr Trash', 'Show Videojsr Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videojsr/trashed.vue'),
      },
    ],
  },
]
