export default [
  {
    path: 'videodriving',
    name: 'videodriving',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'videodriving.list',
        meta: {
          permission: ['Videodriving List', 'Videodriving List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videodriving/list.vue'),
      },
      {
        path: 'create',
        name: 'videodriving.create',
        meta: {
          permission: 'Create Videodriving',
        },
        component: () => import('@/views/dashboard/gtrans/videodriving/create.vue'),
      },
      {
        path: 'edit/:videodriving',
        name: 'videodriving.edit',
        meta: {
          permission: ['Update Videodriving', 'Update Videodriving Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videodriving/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'videodriving.trashed',
        meta: {
          permission: ['Show Videodriving Trash', 'Show Videodriving Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videodriving/trashed.vue'),
      },
    ],
  },
]
