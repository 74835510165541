export default [
  {
    path: 'statisticPublictransport',
    name: 'statisticPublictransport',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'statisticPublictransport.list',
        meta: {
          permission: ['StatisticPublictransport List', 'StatisticPublictransport List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/statisticPublictransport/list.vue'),
      },
      {
        path: 'create',
        name: 'statisticPublictransport.create',
        meta: {
          permission: 'Create StatisticPublictransport',
        },
        component: () => import('@/views/dashboard/gtrans/statisticPublictransport/create.vue'),
      },
      {
        path: 'edit/:statisticPublictransport',
        name: 'statisticPublictransport.edit',
        meta: {
          permission: ['Update StatisticPublictransport', 'Update StatisticPublictransport Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/statisticPublictransport/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'statisticPublictransport.trashed',
        meta: {
          permission: ['Show StatisticPublictransport Trash', 'Show StatisticPublictransport Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/statisticPublictransport/trashed.vue'),
      },
    ],
  },
]
