import Vue from 'vue'
import VueHtmlToPaper from 'vue-html-to-paper'
import store from '@/store'
import axios from '@/libs/axios'
import i18n from '@/libs/i18n'

Vue.prototype.$http = axios
Vue.prototype.$trans = i18n
function fullPath() {
  if (window.location.hostname === 'localhost') return 'http://localhost:8000/'
  return `${window.location.origin}`
}
Vue.prototype.$fullPath = fullPath()

const AccessPlugin = {
  install() {
    Vue.prototype.haveAccess = (required = null, type = 'any') => {
      if (store.getters['auth/isAuth'] === false) return false
      const { role } = store.getters['auth/GetAuth']
      if (role) if (role.name === 'SuperAdmin') return true
      if (required === null) return false
      let permissions = []
      if (role) {
        if (role.permissions) permissions = role.permissions.map(val => val.name)
        else permissions = []
      }
      if (Array.isArray(required)) {
        if (type === 'any' && required.some(e => permissions.includes(e))) return true
        if (type === 'all' && required.every(e => permissions.includes(e))) return true
      } else return permissions.includes(required)
      return false
    }
    Vue.prototype.haveElementAccess = (required = null, createdById = null) => {
      const auth = store.getters['auth/isAuth']
      if (auth) if (auth.role) if (auth.role.name === 'SuperAdmin') return true
      return Vue.prototype.haveAccess(required) && auth.id === createdById
    }
  },
}

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes',
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options)

// or, using the defaults with no stylesheet
Vue.use(VueHtmlToPaper)
Vue.use(AccessPlugin)
