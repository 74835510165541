export default [
  {
    path: 'icon',
    name: 'icon',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'icon.list',
        meta: {
          permission: ['Icon List', 'Icon List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/icon/list.vue'),
      },
      {
        path: 'create',
        name: 'icon.create',
        meta: {
          permission: 'Create Icon',
        },
        component: () => import('@/views/dashboard/gtrans/icon/create.vue'),
      },
      {
        path: 'edit/:icon',
        name: 'icon.edit',
        meta: {
          permission: ['Update Icon', 'Update Icon Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/icon/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'icon.trashed',
        meta: {
          permission: ['Show Icon Trash', 'Show Icon Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/icon/trashed.vue'),
      },
    ],
  },
]
