export default [
  {
    path: 'toll',
    name: 'toll',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'toll.list',
        meta: {
          permission: ['Toll List', 'Toll List Owner'],
        },
        component: () => import('@/views/dashboard/generated/toll/list.vue'),
      },
      {
        path: 'edit/:toll',
        name: 'toll.edit',
        meta: {
          permission: ['Update Toll', 'Update Toll Owner'],
        },
        component: () => import('@/views/dashboard/generated/toll/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'toll.trashed',
        meta: {
          permission: ['Show Toll Trash', 'Show Toll Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/toll/trashed.vue'),
      },
    ],
  },
]
