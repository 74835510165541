export default [
  {
    path: 'reciept',
    name: 'reciept',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'invoice/:check',
        name: 'reciept.invoice',
        meta: {
          permission: ['Reciept Invoice'],
        },
        component: () => import('@/views/dashboard/generated/reciept/invoice.vue'),
      },
      {
        path: 'certificate/:reciept',
        name: 'reciept.certificate',
        meta: {
          permission: ['Reciept Certificate'],
        },
        component: () => import('@/views/dashboard/generated/reciept/certificate.vue'),
      },
      {
        path: 'edit/:reciept',
        name: 'reciept.edit',
        meta: {
          permission: ['Update Reciept', 'Update Reciept Owner'],
        },
        component: () => import('@/views/dashboard/generated/reciept/edit.vue'),
      },
      {
        path: 'list',
        name: 'reciept.list',
        meta: {
          permission: ['Reciept List', 'Reciept List Owner'],
        },
        component: () => import('@/views/dashboard/generated/reciept/list.vue'),
      },
      {
        path: 'create',
        name: 'reciept.create',
        meta: {
          permission: 'Create Reciept',
        },
        component: () => import('@/views/dashboard/generated/reciept/create.vue'),
      },
      {
        path: 'trashed',
        name: 'reciept.trashed',
        meta: {
          permission: ['Show Reciept Trash', 'Show Reciept Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/reciept/trashed.vue'),
      },
    ],
  },
]
