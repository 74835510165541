import Vue from 'vue'

const actions = {

  GetElement({ commit }, element) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.get(`generated/owners/${element}`).then(response => {
        commit('GET_ELEMENT_DATA', response.data.owner)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  GetElements({ commit }) {
    Vue.prototype.$http.get('generated/owners').then(response => {
      commit('GET_ELEMENTS_DATA', response.data.owners)
    })
  },
  getOwners({ commit }) {
    Vue.prototype.$http.get('generated/getOwners').then(response => {
      commit('GET_ELEMENTS_DATA', response.data.owners)
    })
  },
  GetTrashed({ commit }) {
    Vue.prototype.$http.get('generated/owners/trashed').then(response => {
      commit('GET_TRASHED_DATA', response.data.owners)
    })
  },
  Update(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.put(`generated/owners/${payload.id}`, payload).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  Create(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('generated/owners', payload).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  Delete({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.delete(`generated/owners/${payload}`).then(() => {
        dispatch('GetElements')
      }).catch(error => {
        reject(error)
      })
    })
  },
  Restore({ dispatch }, element) {
    Vue.prototype.$http.put(`generated/owners/restore/${element}`).then(() => {
      dispatch('GetTrashed')
    })
  },
}
export default actions
