export default [
  {
    path: 'appoinment',
    name: 'appoinment',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'appoinment.list',
        meta: {
          permission: ['Appoinment List', 'Appoinment List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/appoinment/list.vue'),
      },
      {
        path: 'create',
        name: 'appoinment.create',
        meta: {
          permission: 'Create Appoinment',
        },
        component: () => import('@/views/dashboard/gtrans/appoinment/create.vue'),
      },
      {
        path: 'edit/:appoinment',
        name: 'appoinment.edit',
        meta: {
          permission: ['Update Appoinment', 'Update Appoinment Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/appoinment/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'appoinment.trashed',
        meta: {
          permission: ['Show Appoinment Trash', 'Show Appoinment Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/appoinment/trashed.vue'),
      },
    ],
  },
]
