export default [
  {
    path: 'tnewCertificate',
    name: 'tnewCertificate',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'tnewCertificate.list',
        meta: {
          permission: ['TnewCertificate List', 'TnewCertificate List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewCertificate/list.vue'),
      },
      {
        path: 'create',
        name: 'tnewCertificate.create',
        meta: {
          permission: 'Create TnewCertificate',
        },
        component: () => import('@/views/dashboard/gtrans/tnewCertificate/create.vue'),
      },
      {
        path: 'edit/:tnewCertificate',
        name: 'tnewCertificate.edit',
        meta: {
          permission: ['Update TnewCertificate', 'Update TnewCertificate Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewCertificate/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'tnewCertificate.trashed',
        meta: {
          permission: ['Show TnewCertificate Trash', 'Show TnewCertificate Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewCertificate/trashed.vue'),
      },
    ],
  },
]
