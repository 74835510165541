export default [
  {
    path: 'videoharem',
    name: 'videoharem',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'videoharem.list',
        meta: {
          permission: ['Videoharem List', 'Videoharem List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videoharem/list.vue'),
      },
      {
        path: 'create',
        name: 'videoharem.create',
        meta: {
          permission: 'Create Videoharem',
        },
        component: () => import('@/views/dashboard/gtrans/videoharem/create.vue'),
      },
      {
        path: 'edit/:videoharem',
        name: 'videoharem.edit',
        meta: {
          permission: ['Update Videoharem', 'Update Videoharem Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videoharem/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'videoharem.trashed',
        meta: {
          permission: ['Show Videoharem Trash', 'Show Videoharem Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videoharem/trashed.vue'),
      },
    ],
  },
]
