export default [
  {
    path: 'newschool',
    name: 'newschool',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'newschool.list',
        meta: {
          permission: ['Newschool List', 'Newschool List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/newschool/list.vue'),
      },
      {
        path: 'create',
        name: 'newschool.create',
        meta: {
          permission: 'Create Newschool',
        },
        component: () => import('@/views/dashboard/gtrans/newschool/create.vue'),
      },
      {
        path: 'edit/:newschool',
        name: 'newschool.edit',
        meta: {
          permission: ['Update Newschool', 'Update Newschool Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/newschool/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'newschool.trashed',
        meta: {
          permission: ['Show Newschool Trash', 'Show Newschool Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/newschool/trashed.vue'),
      },
    ],
  },
]
