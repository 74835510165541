export default [
  {
    path: 'check',
    name: 'check',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'invoice/:check',
        name: 'check.invoice',
        meta: {
          permission: ['Check List'],
        },
        component: () => import('@/views/dashboard/generated/check/invoice.vue'),
      },
      {
        path: 'certificate/:check',
        name: 'check.certificate',
        meta: {
          permission: ['Check List'],
        },
        component: () => import('@/views/dashboard/generated/check/certificate.vue'),
      },
      {
        path: 'list',
        name: 'check.list',
        meta: {
          permission: ['Check List', 'Check List Owner'],
        },
        component: () => import('@/views/dashboard/generated/check/list.vue'),
      },
      {
        path: 'edit/:check',
        name: 'check.edit',
        meta: {
          permission: ['Update Check', 'Update Check Owner'],
        },
        component: () => import('@/views/dashboard/generated/check/edit.vue'),
      },
      {
        path: 'create',
        name: 'check.create',
        meta: {
          permission: 'Create Check',
        },
        component: () => import('@/views/dashboard/generated/check/create.vue'),
      },
      {
        path: 'trashed',
        name: 'check.trashed',
        meta: {
          permission: ['Show Check Trash', 'Show Check Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/check/trashed.vue'),
      },
    ],
  },
]
