export default [
  {
    path: 'statisticdr',
    name: 'statisticdr',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'statisticdr.list',
        meta: {
          permission: ['Statisticdr List', 'Statisticdr List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/statisticdr/list.vue'),
      },
      {
        path: 'create',
        name: 'statisticdr.create',
        meta: {
          permission: 'Create Statisticdr',
        },
        component: () => import('@/views/dashboard/gtrans/statisticdr/create.vue'),
      },
      {
        path: 'edit/:statisticdr',
        name: 'statisticdr.edit',
        meta: {
          permission: ['Update Statisticdr', 'Update Statisticdr Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/statisticdr/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'statisticdr.trashed',
        meta: {
          permission: ['Show Statisticdr Trash', 'Show Statisticdr Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/statisticdr/trashed.vue'),
      },
    ],
  },
]
