import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from './auth'
import Home from './home'
import Error from './error'
import Dashboard from './dashboard'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...Home,
    ...Auth,
    ...Error,
    {
      path: '/',
      component: { render: h => h('router-view') },
      meta: {
        authRequired: 'true',
      },
      children: [
        ...Dashboard,
      ],
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    if (localStorage.getItem('isAuth')) {
      if (to.meta.permission) {
        let type = 'any'
        if (to.meta.type)type = to.meta.type
        if (Vue.prototype.haveAccess(to.meta.permission, type)) { return next() }
        return next({ name: 'error-403' })
      }
      return next()
    }
    return next({ name: 'login' })
  }
  return next()
})
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
