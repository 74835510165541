export default [
  {
    path: 'publictransport',
    name: 'publictransport',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'publictransport.list',
        meta: {
          permission: ['Publictransport List', 'Publictransport List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/publictransport/list.vue'),
      },
      {
        path: 'create',
        name: 'publictransport.create',
        meta: {
          permission: 'Create Publictransport',
        },
        component: () => import('@/views/dashboard/gtrans/publictransport/create.vue'),
      },
      {
        path: 'edit/:publictransport',
        name: 'publictransport.edit',
        meta: {
          permission: ['Update Publictransport', 'Update Publictransport Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/publictransport/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'publictransport.trashed',
        meta: {
          permission: ['Show Publictransport Trash', 'Show Publictransport Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/publictransport/trashed.vue'),
      },
    ],
  },
]
