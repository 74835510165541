export default [
  {
    path: 'cimportmquestion',
    name: 'cimportmquestion',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'cimportmquestion.list',
        meta: {
          permission: ['Cimportmquestion List', 'Cimportmquestion List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/cimportmquestion/list.vue'),
      },
      {
        path: 'create',
        name: 'cimportmquestion.create',
        meta: {
          permission: 'Create Cimportmquestion',
        },
        component: () => import('@/views/dashboard/gtrans/cimportmquestion/create.vue'),
      },
      {
        path: 'edit/:cimportmquestion',
        name: 'cimportmquestion.edit',
        meta: {
          permission: ['Update Cimportmquestion', 'Update Cimportmquestion Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/cimportmquestion/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'cimportmquestion.trashed',
        meta: {
          permission: ['Show Cimportmquestion Trash', 'Show Cimportmquestion Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/cimportmquestion/trashed.vue'),
      },
    ],
  },
]
