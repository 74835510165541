export default [
  {
    path: 'videohalab',
    name: 'videohalab',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'videohalab.list',
        meta: {
          permission: ['Videohalab List', 'Videohalab List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videohalab/list.vue'),
      },
      {
        path: 'create',
        name: 'videohalab.create',
        meta: {
          permission: 'Create Videohalab',
        },
        component: () => import('@/views/dashboard/gtrans/videohalab/create.vue'),
      },
      {
        path: 'edit/:videohalab',
        name: 'videohalab.edit',
        meta: {
          permission: ['Update Videohalab', 'Update Videohalab Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videohalab/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'videohalab.trashed',
        meta: {
          permission: ['Show Videohalab Trash', 'Show Videohalab Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videohalab/trashed.vue'),
      },
    ],
  },
]
