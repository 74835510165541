export default [
  {
    path: 'offense',
    name: 'offense',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'offense.list',
        meta: {
          permission: ['Offense List'],
        },
        component: () => import('@/views/dashboard/generated/offense/list.vue'),
      },
    ],
  },
]
