export default [
  {
    path: 'haremdecision',
    name: 'haremdecision',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'haremdecision.list',
        meta: {
          permission: ['Haremdecision List', 'Haremdecision List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/haremdecision/list.vue'),
      },
      {
        path: 'create',
        name: 'haremdecision.create',
        meta: {
          permission: 'Create Haremdecision',
        },
        component: () => import('@/views/dashboard/gtrans/haremdecision/create.vue'),
      },
      {
        path: 'edit/:haremdecision',
        name: 'haremdecision.edit',
        meta: {
          permission: ['Update Haremdecision', 'Update Haremdecision Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/haremdecision/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'haremdecision.trashed',
        meta: {
          permission: ['Show Haremdecision Trash', 'Show Haremdecision Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/haremdecision/trashed.vue'),
      },
    ],
  },
]
