export default [
  {
    path: 'galleryhalab',
    name: 'galleryhalab',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'galleryhalab.list',
        meta: {
          permission: ['Galleryhalab List', 'Galleryhalab List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/galleryhalab/list.vue'),
      },
      {
        path: 'create',
        name: 'galleryhalab.create',
        meta: {
          permission: 'Create Galleryhalab',
        },
        component: () => import('@/views/dashboard/gtrans/galleryhalab/create.vue'),
      },
      {
        path: 'edit/:galleryhalab',
        name: 'galleryhalab.edit',
        meta: {
          permission: ['Update Galleryhalab', 'Update Galleryhalab Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/galleryhalab/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'galleryhalab.trashed',
        meta: {
          permission: ['Show Galleryhalab Trash', 'Show Galleryhalab Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/galleryhalab/trashed.vue'),
      },
    ],
  },
]
