export default [
  {
    path: 'practical_test',
    name: 'practical_test',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'practicalTest.list',
        meta: {
          permission: ['Practical_test List', 'Practical_test List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/practical_test/list.vue'),
      },
      {
        path: 'create',
        name: 'practicalTest.create',
        meta: {
          permission: 'Create Practical_test',
        },
        component: () => import('@/views/dashboard/gtrans/practical_test/create.vue'),
      },
      {
        path: 'edit/:practicalTest',
        name: 'practicalTest.edit',
        meta: {
          permission: ['Update Practical_test', 'Update Practical_test Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/practical_test/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'practicalTest.trashed',
        meta: {
          permission: ['Show Practical_test Trash', 'Show Practical_test Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/practical_test/trashed.vue'),
      },
    ],
  },
]
