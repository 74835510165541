export default [
  {
    path: 'previewPublictransport',
    name: 'previewPublictransport',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'previewPublictransport.list',
        meta: {
          permission: ['PreviewPublictransport List', 'PreviewPublictransport List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/previewPublictransport/list.vue'),
      },
      {
        path: 'create',
        name: 'previewPublictransport.create',
        meta: {
          permission: 'Create PreviewPublictransport',
        },
        component: () => import('@/views/dashboard/gtrans/previewPublictransport/create.vue'),
      },
      {
        path: 'edit/:previewPublictransport',
        name: 'previewPublictransport.edit',
        meta: {
          permission: ['Update PreviewPublictransport', 'Update PreviewPublictransport Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/previewPublictransport/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'previewPublictransport.trashed',
        meta: {
          permission: ['Show PreviewPublictransport Trash', 'Show PreviewPublictransport Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/previewPublictransport/trashed.vue'),
      },
    ],
  },
]
