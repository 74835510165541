export default [
  {
    path: 'achiveLicense',
    name: 'achiveLicense',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'achiveLicense.list',
        meta: {
          permission: ['AchiveLicense List', 'AchiveLicense List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/achiveLicense/list.vue'),
      },
      {
        path: 'create',
        name: 'achiveLicense.create',
        meta: {
          permission: 'Create AchiveLicense',
        },
        component: () => import('@/views/dashboard/gtrans/achiveLicense/create.vue'),
      },
      {
        path: 'edit/:achiveLicense',
        name: 'achiveLicense.edit',
        meta: {
          permission: ['Update AchiveLicense', 'Update AchiveLicense Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/achiveLicense/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'achiveLicense.trashed',
        meta: {
          permission: ['Show AchiveLicense Trash', 'Show AchiveLicense Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/achiveLicense/trashed.vue'),
      },
    ],
  },
]
