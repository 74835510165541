export default [
  {
    path: 'carr',
    name: 'carr',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'carr.list',
        meta: {
          permission: ['Carr List', 'Carr List Owner'],
        },
        component: () => import('@/views/dashboard/generated/carr/list.vue'),
      },
      {
        path: 'create',
        name: 'carr.create',
        meta: {
          permission: 'Create Carr',
        },
        component: () => import('@/views/dashboard/generated/carr/create.vue'),
      },
      {
        path: 'edit/:carr',
        name: 'carr.edit',
        meta: {
          permission: ['Update Carr', 'Update Carr Owner'],
        },
        component: () => import('@/views/dashboard/generated/carr/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'carr.trashed',
        meta: {
          permission: ['Show Carr Trash', 'Show Carr Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/carr/trashed.vue'),
      },
    ],
  },
]
