export default [
  {
    path: 'iconPublictransport',
    name: 'iconPublictransport',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'iconPublictransport.list',
        meta: {
          permission: ['IconPublictransport List', 'IconPublictransport List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/iconPublictransport/list.vue'),
      },
      {
        path: 'create',
        name: 'iconPublictransport.create',
        meta: {
          permission: 'Create IconPublictransport',
        },
        component: () => import('@/views/dashboard/gtrans/iconPublictransport/create.vue'),
      },
      {
        path: 'edit/:iconPublictransport',
        name: 'iconPublictransport.edit',
        meta: {
          permission: ['Update IconPublictransport', 'Update IconPublictransport Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/iconPublictransport/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'iconPublictransport.trashed',
        meta: {
          permission: ['Show IconPublictransport Trash', 'Show IconPublictransport Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/iconPublictransport/trashed.vue'),
      },
    ],
  },
]
