export default [
  {
    path: 'decisionPublictransport',
    name: 'decisionPublictransport',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'decisionPublictransport.list',
        meta: {
          permission: ['DecisionPublictransport List', 'DecisionPublictransport List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/decisionPublictransport/list.vue'),
      },
      {
        path: 'create',
        name: 'decisionPublictransport.create',
        meta: {
          permission: 'Create DecisionPublictransport',
        },
        component: () => import('@/views/dashboard/gtrans/decisionPublictransport/create.vue'),
      },
      {
        path: 'edit/:decisionPublictransport',
        name: 'decisionPublictransport.edit',
        meta: {
          permission: ['Update DecisionPublictransport', 'Update DecisionPublictransport Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/decisionPublictransport/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'decisionPublictransport.trashed',
        meta: {
          permission: ['Show DecisionPublictransport Trash', 'Show DecisionPublictransport Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/decisionPublictransport/trashed.vue'),
      },
    ],
  },
]
