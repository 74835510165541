import Vue from 'vue'
import {
  BootstrapVue, IconsPlugin, ToastPlugin, ModalPlugin,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import VueI18n from 'vue-i18n'
import i18n from '@/libs/i18n'
import store from './store'
import router from './router'
import App from './App.vue'

// Global Components
import './global-components'
// Global Variables
import './global-variables'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import '@/libs/sweet-alerts'

import 'vue-toastification/dist/index.css'

import '@core/scss/vue/libs/vue-sweetalert.scss'
import '@core/scss/vue/libs/quill.scss'
import '@core/scss/vue/libs/toastification.scss'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueI18n)
// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
if (localStorage.getItem('isAuth')) store.dispatch('auth/UpdateAuth').then()
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
