export default [
  {
    path: 'tnew',
    name: 'tnew',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'tnew.list',
        meta: {
          permission: ['Tnew List', 'Tnew List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnew/list.vue'),
      },
      {
        path: 'create',
        name: 'tnew.create',
        meta: {
          permission: 'Create Tnew',
        },
        component: () => import('@/views/dashboard/gtrans/tnew/create.vue'),
      },
      {
        path: 'edit/:tnew',
        name: 'tnew.edit',
        meta: {
          permission: ['Update Tnew', 'Update Tnew Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnew/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'tnew.trashed',
        meta: {
          permission: ['Show Tnew Trash', 'Show Tnew Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnew/trashed.vue'),
      },
    ],
  },
]
