export default [
  {
    path: 'videomain',
    name: 'videomain',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'videomain.list',
        meta: {
          permission: ['Videomain List', 'Videomain List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videomain/list.vue'),
      },
      {
        path: 'create',
        name: 'videomain.create',
        meta: {
          permission: 'Create Videomain',
        },
        component: () => import('@/views/dashboard/gtrans/videomain/create.vue'),
      },
      {
        path: 'edit/:videomain',
        name: 'videomain.edit',
        meta: {
          permission: ['Update Videomain', 'Update Videomain Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videomain/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'videomain.trashed',
        meta: {
          permission: ['Show Videomain Trash', 'Show Videomain Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videomain/trashed.vue'),
      },
    ],
  },
]
