import tcompany from './tcompany'

import icon from './icon'

import sicon from './sicon'

import preview from './preview'

import slaydmain from './slaydmain'

import videomain from './videomain'

import videoidlib from './videoidlib'

import first from './first'

import statistic from './statistic'

import statisticdr from './statisticdr'

import achive from './achive'

import complaints from './complaints'

import services from './services'

import karaj from './karaj'

import tnew from './tnew'

import gallery from './gallery'

import mquestion from './mquestion'

import appoinment from './appoinment'

import school from './school'

import sprice from './sprice'

import tbackground from './tbackground'

import ticon from './ticon'

import tgallery from './tgallery'

import tpreview from './tpreview'

import ttnew from './ttnew'

import decision from './decision'

import tdecision from './tdecision'

import idlibbackground from './idlibbackground'

import halabbackground from './halabbackground'

import jsrbackground from './jsrbackground'

import harembackground from './harembackground'

import cimportbackground from './cimportbackground'

import haremstatistic from './haremstatistic'

import cimportstatistic from './cimportstatistic'

import jsrstatistic from './jsrstatistic'

import halabstatistic from './halabstatistic'

import idlibstatistic from './idlibstatistic'

import idlibicon from './idlibicon'

import halabicon from './halabicon'

import haremicon from './haremicon'

import jsricon from './jsricon'

import cimporticon from './cimporticon'

import idlibmquestion from './idlibmquestion'

import halabmquestion from './halabmquestion'

import haremmquestion from './haremmquestion'

import jsrmquestion from './jsrmquestion'

import cimportmquestion from './cimportmquestion'

import idlibdecision from './idlibdecision'

import halabdecision from './halabdecision'

import haremdecision from './haremdecision'

import jsrdecision from './jsrdecision'

import cimportdecision from './cimportdecision'

import tnewidlib from './tnewidlib'

import tnewhalab from './tnewhalab'

import tnewharem from './tnewharem'

import tnewcimport from './tnewcimport'

import tnewjsr from './tnewjsr'

import slayddriving from './slayddriving'

import videodriving from './videodriving'

import videohalab from './videohalab'

import videojsr from './videojsr'

import drdecision from './drdecision'

import drnew from './drnew'

import drgallery from './drgallery'

import achiveLicensecondition from './achiveLicensecondition'

import achiveLicense from './achiveLicense'

import privewLicense from './privewLicense'

import tnewLicense from './tnewLicense'

import decisionLicense from './decisionLicense'

import backgroundCertificate from './backgroundCertificate'

import firstRegistration from './firstRegistration'

import secondRegistration from './secondRegistration'

import practicalTest from './practicalTest'

import medicalTest from './medicalTest'

import theoryTest from './theoryTest'

import tnewCertificate from './tnewCertificate'

import decisionCertificate from './decisionCertificate'

import publictransport from './publictransport'

import statisticPublictransport from './statisticPublictransport'

import videoPublictransport from './videoPublictransport'

import achivePublictransport from './achivePublictransport'

import iconPublictransport from './iconPublictransport'

import previewPublictransport from './previewPublictransport'

import tnewPublictransport from './tnewPublictransport'

import decisionPublictransport from './decisionPublictransport'

import achiveAbout from './achiveAbout'

import videocimport from './videocimport'

import videoharem from './videoharem'

import around from './around'

import employee from './employee'

import connect from './connect'

import mnew from './mnew'

import tvideo from './tvideo'

import connectidlib from './connectidlib'

import connecthalab from './connecthalab'

import connectjsr from './connectjsr'

import connectcimport from './connectcimport'

import connectharem from './connectharem'

import galleryharem from './galleryharem'

import galleryhalab from './galleryhalab'

import gallerycimport from './gallerycimport'

import galleryidlib from './galleryidlib'

import galleryjsr from './galleryjsr'

import backgroundlicense from './backgroundlicense'

import newschool from './newschool'

import text from './text'

import line from './line'

export default {
  modules: {
    line,
    text,
    newschool,
    backgroundlicense,
    galleryharem,
    galleryhalab,
    gallerycimport,
    galleryidlib,
    galleryjsr,
    connectidlib,
    connectharem,
    connectcimport,
    connectjsr,
    connecthalab,
    tvideo,
    tcompany,
    employee,
    icon,
    videojsr,
    videocimport,
    sicon,
    preview,
    slaydmain,
    videomain,
    first,
    statistic,
    statisticdr,
    achive,
    complaints,
    services,
    karaj,
    tnew,
    gallery,
    mquestion,
    appoinment,
    school,
    connect,
    mnew,
    around,
    videoharem,
    sprice,
    tbackground,
    ticon,
    tgallery,
    tpreview,
    ttnew,
    decision,
    tdecision,
    idlibbackground,
    halabbackground,
    harembackground,
    cimportbackground,
    jsrbackground,
    haremstatistic,
    cimportstatistic,
    jsrstatistic,
    halabstatistic,
    idlibstatistic,
    idlibicon,
    videoidlib,
    videohalab,
    halabicon,
    haremicon,
    jsricon,
    cimporticon,
    idlibmquestion,
    halabmquestion,
    haremmquestion,
    jsrmquestion,
    cimportmquestion,
    idlibdecision,
    halabdecision,
    haremdecision,
    jsrdecision,
    cimportdecision,
    tnewidlib,
    tnewhalab,
    tnewharem,
    tnewcimport,
    tnewjsr,
    slayddriving,
    videodriving,
    drdecision,
    drnew,
    drgallery,
    achiveLicensecondition,
    achiveLicense,
    privewLicense,
    tnewLicense,
    decisionLicense,
    backgroundCertificate,
    firstRegistration,
    secondRegistration,
    practicalTest,
    medicalTest,
    theoryTest,
    tnewCertificate,
    decisionCertificate,
    publictransport,
    statisticPublictransport,
    videoPublictransport,
    achivePublictransport,
    iconPublictransport,
    previewPublictransport,
    tnewPublictransport,
    decisionPublictransport,
    achiveAbout,
  },
}
