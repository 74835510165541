export default [
  {
    path: 'old',
    name: 'old',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'invoice/:check',
        name: 'old.invoice',
        meta: {
          permission: ['Checkk Invoice'],
        },
        component: () => import('@/views/dashboard/generated/old/invoice.vue'),
      },
      {
        path: 'certificate/:old',
        name: 'old.certificate',
        meta: {
          permission: ['Checkk Certificate'],
        },
        component: () => import('@/views/dashboard/generated/old/certificate.vue'),
      },
      {
        path: 'edit/:old',
        name: 'old.edit',
        meta: {
          permission: ['Update Old', 'Update Old Owner'],
        },
        component: () => import('@/views/dashboard/generated/old/edit.vue'),
      },
      {
        path: 'list',
        name: 'old.list',
        meta: {
          permission: ['Checkk List', 'Checkk List Owner'],
        },
        component: () => import('@/views/dashboard/generated/old/list.vue'),
      },
      {
        path: 'create',
        name: 'old.create',
        meta: {
          permission: 'Create Checkk',
        },
        component: () => import('@/views/dashboard/generated/old/create.vue'),
      },
      {
        path: 'trashed',
        name: 'old.trashed',
        meta: {
          permission: ['Show Checkk Trash', 'Show Checkk Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/old/trashed.vue'),
      },
    ],
  },
]
