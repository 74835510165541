export default [
  {
    path: 'videocimport',
    name: 'videocimport',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'videocimport.list',
        meta: {
          permission: ['Videocimport List', 'Videocimport List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videocimport/list.vue'),
      },
      {
        path: 'create',
        name: 'videocimport.create',
        meta: {
          permission: 'Create Videocimport',
        },
        component: () => import('@/views/dashboard/gtrans/videocimport/create.vue'),
      },
      {
        path: 'edit/:videocimport',
        name: 'videocimport.edit',
        meta: {
          permission: ['Update Videocimport', 'Update Videocimport Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videocimport/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'videocimport.trashed',
        meta: {
          permission: ['Show Videocimport Trash', 'Show Videocimport Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videocimport/trashed.vue'),
      },
    ],
  },
]
