export default [
  {
    path: 'galleryjsr',
    name: 'galleryjsr',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'galleryjsr.list',
        meta: {
          permission: ['Galleryjsr List', 'Galleryjsr List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/galleryjsr/list.vue'),
      },
      {
        path: 'create',
        name: 'galleryjsr.create',
        meta: {
          permission: 'Create Galleryjsr',
        },
        component: () => import('@/views/dashboard/gtrans/galleryjsr/create.vue'),
      },
      {
        path: 'edit/:galleryjsr',
        name: 'galleryjsr.edit',
        meta: {
          permission: ['Update Galleryjsr', 'Update Galleryjsr Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/galleryjsr/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'galleryjsr.trashed',
        meta: {
          permission: ['Show Galleryjsr Trash', 'Show Galleryjsr Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/galleryjsr/trashed.vue'),
      },
    ],
  },
]
