export default [
  {
    path: 'decisionLicense',
    name: 'decisionLicense',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'decisionLicense.list',
        meta: {
          permission: ['DecisionLicense List', 'DecisionLicense List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/decisionLicense/list.vue'),
      },
      {
        path: 'create',
        name: 'decisionLicense.create',
        meta: {
          permission: 'Create DecisionLicense',
        },
        component: () => import('@/views/dashboard/gtrans/decisionLicense/create.vue'),
      },
      {
        path: 'edit/:decisionLicense',
        name: 'decisionLicense.edit',
        meta: {
          permission: ['Update DecisionLicense', 'Update DecisionLicense Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/decisionLicense/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'decisionLicense.trashed',
        meta: {
          permission: ['Show DecisionLicense Trash', 'Show DecisionLicense Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/decisionLicense/trashed.vue'),
      },
    ],
  },
]
