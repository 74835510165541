export default [
  {
    path: 'tnewidlib',
    name: 'tnewidlib',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'tnewidlib.list',
        meta: {
          permission: ['Tnewidlib List', 'Tnewidlib List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewidlib/list.vue'),
      },
      {
        path: 'create',
        name: 'tnewidlib.create',
        meta: {
          permission: 'Create Tnewidlib',
        },
        component: () => import('@/views/dashboard/gtrans/tnewidlib/create.vue'),
      },
      {
        path: 'edit/:tnewidlib',
        name: 'tnewidlib.edit',
        meta: {
          permission: ['Update Tnewidlib', 'Update Tnewidlib Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewidlib/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'tnewidlib.trashed',
        meta: {
          permission: ['Show Tnewidlib Trash', 'Show Tnewidlib Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewidlib/trashed.vue'),
      },
    ],
  },
]
