export default [
  {
    path: 'platee',
    name: 'platee',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'platee.list',
        meta: {
          permission: ['Platee List'],
        },
        component: () => import('@/views/dashboard/generated/platee/list.vue'),
      },
    ],
  },
]
