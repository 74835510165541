import Tcompany from './tcompany'

import Icon from './icon'

import Preview from './preview'

import Sicon from './sicon'

import Slaydmain from './slaydmain'

import Videomain from './videomain'

import Videoidlib from './videoidlib'

import First from './first'

import Statistic from './statistic'

import Statisticdr from './statisticdr'

import Achive from './achive'

import Complaints from './complaints'

import Services from './services'

import Karaj from './karaj'

import Tnew from './tnew'

import Gallery from './gallery'

import Mquestion from './mquestion'

import Appoinment from './appoinment'

import School from './school'

import Sprice from './sprice'

import Tbackground from './tbackground'

import Ticon from './ticon'

import Tgallery from './tgallery'

import Tpreview from './tpreview'

import Ttnew from './ttnew'

import Decision from './decision'

import Tdecision from './tdecision'

import Idlibbackground from './idlibbackground'

import Halabbackground from './halabbackground'

import Harembackground from './harembackground'

import Cimportbackground from './cimportbackground'

import Jsrbackground from './jsrbackground'

import Idlibstatistic from './idlibstatistic'

import Halabstatistic from './halabstatistic'

import Jsrstatistic from './jsrstatistic'

import Cimportstatistic from './cimportstatistic'

import Haremstatistic from './haremstatistic'

import Idlibicon from './idlibicon'

import Halabicon from './halabicon'

import Haremicon from './haremicon'

import Jsricon from './jsricon'

import Cimporticon from './cimporticon'

import Idlibmquestion from './idlibmquestion'

import Halabmquestion from './halabmquestion'

import Haremmquestion from './haremmquestion'

import Jsrmquestion from './jsrmquestion'

import Cimmquestion from './cimportmquestion'

import Idlibdecision from './idlibdecision'

import Halabdecision from './halabdecision'

import Haremdecision from './haremdecision'

import Jsrdecision from './jsrdecision'

import Cimdecision from './cimportdecision'

import Tnewidlib from './tnewidlib'

import Tnewhalab from './tnewhalab'

import Tnewharem from './tnewharem'

import Tnewcimport from './tnewcimport'

import Tnewjsr from './tnewjsr'

import Slayddriving from './slayddriving'

import Videodriving from './videodriving'

import Videohalab from './videohalab'

import Videojsr from './videojsr'

import Videocimport from './videocimport'

import Videoharem from './videoharem'

import Drdecision from './drdecision'

import Drnew from './drnew'

import Drgallery from './drgallery'

import AchiveLicensecondition from './achiveLicensecondition'

import AchiveLicense from './achiveLicense'

import PrivewLicense from './privewLicense'

import TnewLicense from './tnewLicense'

import DecisionLicense from './decisionLicense'

import BackgroundCertificate from './backgroundCertificate'

import Around from './around'

import Employee from './employee'

import FirstRegistration from './firstRegistration'

import SecondRegistration from './secondRegistration'

import PracticalTest from './practicalTest'

import MedicalTest from './medicalTest'

import TheoryTest from './theoryTest'

import TnewCertificate from './tnewCertificate'

import DecisionCertificate from './decisionCertificate'

import Publictransport from './publictransport'

import StatisticPublictransport from './statisticPublictransport'

import VideoPublictransport from './videoPublictransport'

import AchivePublictransport from './achivePublictransport'

import IconPublictransport from './iconPublictransport'

import PreviewPublictransport from './previewPublictransport'

import TnewPublictransport from './tnewPublictransport'

import DecisionPublictransport from './decisionPublictransport'

import AchiveAbout from './achiveAbout'

import Connect from './connect'

import Mnew from './mnew'

import Tvideo from './tvideo'

import Connectidlib from './connectidlib'

import Connecthalab from './connecthalab'

import Connectjsr from './connectjsr'

import Connectcimport from './connectcimport'

import Connectharem from './connectharem'

import Galleryharem from './galleryharem'

import Gallerycimport from './gallerycimport'

import Galleryhalab from './galleryhalab'

import Galleryidlib from './galleryidlib'

import Galleryjsr from './galleryjsr'

import Backgroundlicense from './backgroundlicense'

import Newschool from './newschool'

import Text from './text'

import Line from './line'

export default [
  ...Text,
  ...Newschool,
  ...Backgroundlicense,
  ...Statisticdr,
  ...Galleryharem,
  ...Gallerycimport,
  ...Galleryhalab,
  ...Galleryidlib,
  ...Galleryjsr,
  ...Connectharem,
  ...Connectcimport,
  ...Connectidlib,
  ...Connectjsr,
  ...Connecthalab,
  ...Tvideo,
  ...Tcompany,
  ...Icon,
  ...Line,
  ...Mnew,
  ...Preview,
  ...Sicon,
  ...Slaydmain,
  ...Videomain,
  ...First,
  ...Statistic,
  ...Achive,
  ...Complaints,
  ...Services,
  ...Karaj,
  ...Tnew,
  ...Gallery,
  ...Mquestion,
  ...Appoinment,
  ...Videocimport,
  ...Videoharem,
  ...School,
  ...Sprice,
  ...Videojsr,
  ...Videocimport,
  ...Tbackground,
  ...Ticon,
  ...Tgallery,
  ...Tpreview,
  ...Ttnew,
  ...Decision,
  ...Employee,
  ...Connect,
  ...Tdecision,
  ...Videoidlib,
  ...Videohalab,
  ...Idlibbackground,
  ...Jsrbackground,
  ...Halabbackground,
  ...Harembackground,
  ...Cimportbackground,
  ...Halabstatistic,
  ...Haremstatistic,
  ...Cimportstatistic,
  ...Jsrstatistic,
  ...Idlibstatistic,
  ...Idlibicon,
  ...Halabicon,
  ...Haremicon,
  ...Jsricon,
  ...Cimporticon,
  ...Idlibmquestion,
  ...Halabmquestion,
  ...Haremmquestion,
  ...Jsrmquestion,
  ...Cimmquestion,
  ...Idlibdecision,
  ...Halabdecision,
  ...Haremdecision,
  ...Jsrdecision,
  ...Cimdecision,
  ...Tnewidlib,
  ...Tnewhalab,
  ...Tnewharem,
  ...Tnewcimport,
  ...Tnewjsr,
  ...Slayddriving,
  ...Videodriving,
  ...Drdecision,
  ...Drnew,
  ...Drgallery,
  ...AchiveLicensecondition,
  ...AchiveLicense,
  ...PrivewLicense,
  ...TnewLicense,
  ...DecisionLicense,
  ...BackgroundCertificate,
  ...FirstRegistration,
  ...Around,
  ...SecondRegistration,
  ...PracticalTest,
  ...MedicalTest,
  ...TheoryTest,
  ...TnewCertificate,
  ...DecisionCertificate,
  ...Publictransport,
  ...StatisticPublictransport,
  ...VideoPublictransport,
  ...AchivePublictransport,
  ...IconPublictransport,
  ...PreviewPublictransport,
  ...TnewPublictransport,
  ...DecisionPublictransport,
  ...AchiveAbout,
]
