export default [
  {
    path: 'jsricon',
    name: 'jsricon',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'jsricon.list',
        meta: {
          permission: ['Jsricon List', 'Jsricon List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/jsricon/list.vue'),
      },
      {
        path: 'create',
        name: 'jsricon.create',
        meta: {
          permission: 'Create Jsricon',
        },
        component: () => import('@/views/dashboard/gtrans/jsricon/create.vue'),
      },
      {
        path: 'edit/:jsricon',
        name: 'jsricon.edit',
        meta: {
          permission: ['Update Jsricon', 'Update Jsricon Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/jsricon/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'jsricon.trashed',
        meta: {
          permission: ['Show Jsricon Trash', 'Show Jsricon Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/jsricon/trashed.vue'),
      },
    ],
  },
]
