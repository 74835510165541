export default [
  {
    path: 'mquestion',
    name: 'mquestion',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'mquestion.list',
        meta: {
          permission: ['Mquestion List', 'Mquestion List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/mquestion/list.vue'),
      },
      {
        path: 'create',
        name: 'mquestion.create',
        meta: {
          permission: 'Create Mquestion',
        },
        component: () => import('@/views/dashboard/gtrans/mquestion/create.vue'),
      },
      {
        path: 'edit/:mquestion',
        name: 'mquestion.edit',
        meta: {
          permission: ['Update Mquestion', 'Update Mquestion Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/mquestion/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'mquestion.trashed',
        meta: {
          permission: ['Show Mquestion Trash', 'Show Mquestion Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/mquestion/trashed.vue'),
      },
    ],
  },
]
