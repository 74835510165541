export default [
  {
    path: 'gallery',
    name: 'gallery',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'gallery.list',
        meta: {
          permission: ['Gallery List', 'Gallery List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/gallery/list.vue'),
      },
      {
        path: 'create',
        name: 'gallery.create',
        meta: {
          permission: 'Create Gallery',
        },
        component: () => import('@/views/dashboard/gtrans/gallery/create.vue'),
      },
      {
        path: 'edit/:gallery',
        name: 'gallery.edit',
        meta: {
          permission: ['Update Gallery', 'Update Gallery Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/gallery/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'gallery.trashed',
        meta: {
          permission: ['Show Gallery Trash', 'Show Gallery Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/gallery/trashed.vue'),
      },
    ],
  },
]
