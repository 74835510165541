export default [
  {
    path: 'around',
    name: 'around',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'around.list',
        meta: {
          permission: ['Around List', 'Around List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/around/list.vue'),
      },
      {
        path: 'create',
        name: 'around.create',
        meta: {
          permission: 'Create Around',
        },
        component: () => import('@/views/dashboard/gtrans/around/create.vue'),
      },
      {
        path: 'edit/:around',
        name: 'around.edit',
        meta: {
          permission: ['Update Around', 'Update Around Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/around/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'around.trashed',
        meta: {
          permission: ['Show Around Trash', 'Show Around Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/around/trashed.vue'),
      },
    ],
  },
]
