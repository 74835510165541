export default [
  {
    path: 'jsrmquestion',
    name: 'jsrmquestion',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'jsrmquestion.list',
        meta: {
          permission: ['Jsrmquestion List', 'Jsrmquestion List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/jsrmquestion/list.vue'),
      },
      {
        path: 'create',
        name: 'jsrmquestion.create',
        meta: {
          permission: 'Create Jsrmquestion',
        },
        component: () => import('@/views/dashboard/gtrans/jsrmquestion/create.vue'),
      },
      {
        path: 'edit/:jsrmquestion',
        name: 'jsrmquestion.edit',
        meta: {
          permission: ['Update Jsrmquestion', 'Update Jsrmquestion Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/jsrmquestion/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'jsrmquestion.trashed',
        meta: {
          permission: ['Show Jsrmquestion Trash', 'Show Jsrmquestion Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/jsrmquestion/trashed.vue'),
      },
    ],
  },
]
