export default [
  {
    path: 'connectjsr',
    name: 'connectjsr',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'connectjsr.list',
        meta: {
          permission: ['Connectjsr List', 'Connectjsr List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/connectjsr/list.vue'),
      },
      {
        path: 'create',
        name: 'connectjsr.create',
        meta: {
          permission: 'Create Connectjsr',
        },
        component: () => import('@/views/dashboard/gtrans/connectjsr/create.vue'),
      },
      {
        path: 'edit/:connectjsr',
        name: 'connectjsr.edit',
        meta: {
          permission: ['Update Connectjsr', 'Update Connectjsr Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/connectjsr/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'connectjsr.trashed',
        meta: {
          permission: ['Show Connectjsr Trash', 'Show Connectjsr Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/connectjsr/trashed.vue'),
      },
    ],
  },
]
