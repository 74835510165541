export default [
  {
    path: 'second_registration',
    name: 'second_registration',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'secondRegistration.list',
        meta: {
          permission: ['Second_registration List', 'Second_registration List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/second_registration/list.vue'),
      },
      {
        path: 'create',
        name: 'secondRegistration.create',
        meta: {
          permission: 'Create Second_registration',
        },
        component: () => import('@/views/dashboard/gtrans/second_registration/create.vue'),
      },
      {
        path: 'edit/:secondRegistration',
        name: 'secondRegistration.edit',
        meta: {
          permission: ['Update Second_registration', 'Update Second_registration Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/second_registration/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'secondRegistration.trashed',
        meta: {
          permission: ['Show Second_registration Trash', 'Show Second_registration Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/second_registration/trashed.vue'),
      },
    ],
  },
]
