export default [
  {
    path: 'mnew',
    name: 'mnew',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'mnew.list',
        meta: {
          permission: ['Mnew List', 'Mnew List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/mnew/list.vue'),
      },
      {
        path: 'create',
        name: 'mnew.create',
        meta: {
          permission: 'Create Mnew',
        },
        component: () => import('@/views/dashboard/gtrans/mnew/create.vue'),
      },
      {
        path: 'edit/:mnew',
        name: 'mnew.edit',
        meta: {
          permission: ['Update Mnew', 'Update Mnew Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/mnew/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'mnew.trashed',
        meta: {
          permission: ['Show Mnew Trash', 'Show Mnew Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/mnew/trashed.vue'),
      },
    ],
  },
]
