export default [
  {
    path: 'renew',
    name: 'renew',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'renew.list',
        meta: {
          permission: ['Renew List', 'Renew List Owner'],
        },
        component: () => import('@/views/dashboard/generated/renew/list.vue'),
      },
      {
        path: 'edit/:renew',
        name: 'renew.edit',
        meta: {
          permission: ['Update Renew', 'Update Renew Owner'],
        },
        component: () => import('@/views/dashboard/generated/renew/edit.vue'),
      },
      {
        path: 'create',
        name: 'renew.create',
        meta: {
          permission: 'Create Renew',
        },
        component: () => import('@/views/dashboard/generated/renew/create.vue'),
      },
      {
        path: 'trashed',
        name: 'renew.trashed',
        meta: {
          permission: ['Show Renew Trash', 'Show Renew Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/renew/trashed.vue'),
      },
    ],
  },
]
