export default [
  {
    path: 'tpreview',
    name: 'tpreview',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'tpreview.list',
        meta: {
          permission: ['Tpreview List', 'Tpreview List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tpreview/list.vue'),
      },
      {
        path: 'create',
        name: 'tpreview.create',
        meta: {
          permission: 'Create Tpreview',
        },
        component: () => import('@/views/dashboard/gtrans/tpreview/create.vue'),
      },
      {
        path: 'edit/:tpreview',
        name: 'tpreview.edit',
        meta: {
          permission: ['Update Tpreview', 'Update Tpreview Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tpreview/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'tpreview.trashed',
        meta: {
          permission: ['Show Tpreview Trash', 'Show Tpreview Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tpreview/trashed.vue'),
      },
    ],
  },
]
