export default [
  {
    path: 'achiveAbout',
    name: 'achiveAbout',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'achiveAbout.list',
        meta: {
          permission: ['AchiveAbout List', 'AchiveAbout List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/achiveAbout/list.vue'),
      },
      {
        path: 'create',
        name: 'achiveAbout.create',
        meta: {
          permission: 'Create AchiveAbout',
        },
        component: () => import('@/views/dashboard/gtrans/achiveAbout/create.vue'),
      },
      {
        path: 'edit/:achiveAbout',
        name: 'achiveAbout.edit',
        meta: {
          permission: ['Update AchiveAbout', 'Update AchiveAbout Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/achiveAbout/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'achiveAbout.trashed',
        meta: {
          permission: ['Show AchiveAbout Trash', 'Show AchiveAbout Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/achiveAbout/trashed.vue'),
      },
    ],
  },
]
