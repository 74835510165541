export default [
  {
    path: 'cimportstatistic',
    name: 'cimportstatistic',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'cimportstatistic.list',
        meta: {
          permission: ['Cimportstatistic List', 'Cimportstatistic List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/cimportstatistic/list.vue'),
      },
      {
        path: 'create',
        name: 'cimportstatistic.create',
        meta: {
          permission: 'Create Cimportstatistic',
        },
        component: () => import('@/views/dashboard/gtrans/cimportstatistic/create.vue'),
      },
      {
        path: 'edit/:cimportstatistic',
        name: 'cimportstatistic.edit',
        meta: {
          permission: ['Update Cimportstatistic', 'Update Cimportstatistic Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/cimportstatistic/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'cimportstatistic.trashed',
        meta: {
          permission: ['Show Cimportstatistic Trash', 'Show Cimportstatistic Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/cimportstatistic/trashed.vue'),
      },
    ],
  },
]
