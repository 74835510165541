export default [
  {
    path: 'company',
    name: 'company',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'company.list',
        meta: {
          permission: ['Company List'],
        },
        component: () => import('@/views/dashboard/generated/company/list.vue'),
      },
    ],
  },
]
