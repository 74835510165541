export default [
  {
    path: 'sale',
    name: 'sale',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'sale.list',
        meta: {
          permission: ['Sale List', 'Sale List Owner'],
        },
        component: () => import('@/views/dashboard/generated/sale/list.vue'),
      },
      {
        path: 'edit/:sale',
        name: 'sale.edit',
        meta: {
          permission: ['Update Sale', 'Update Sale Owner'],
        },
        component: () => import('@/views/dashboard/generated/sale/edit.vue'),
      },
      {
        path: 'create',
        name: 'sale.create',
        meta: {
          permission: 'Create Sale',
        },
        component: () => import('@/views/dashboard/generated/sale/create.vue'),
      },
      {
        path: 'trashed',
        name: 'sale.trashed',
        meta: {
          permission: ['Show Sale Trash', 'Show Sale Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/sale/trashed.vue'),
      },
    ],
  },
]
