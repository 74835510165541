export default [
  {
    path: 'delivery',
    name: 'delivery',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'invoice/:check',
        name: 'delivery.invoice',
        meta: {
          permission: ['Delivery Invoice'],
        },
        component: () => import('@/views/dashboard/generated/delivery/invoice.vue'),
      },
      {
        path: 'certificate/:delivery',
        name: 'delivery.certificate',
        meta: {
          permission: ['Delivery Certificate'],
        },
        component: () => import('@/views/dashboard/generated/delivery/certificate.vue'),
      },
      {
        path: 'edit/:delivery',
        name: 'delivery.edit',
        meta: {
          permission: ['Update Delivery', 'Update Delivery Owner'],
        },
        component: () => import('@/views/dashboard/generated/delivery/edit.vue'),
      },
      {
        path: 'list',
        name: 'delivery.list',
        meta: {
          permission: ['Delivery List', 'Delivery List Owner'],
        },
        component: () => import('@/views/dashboard/generated/delivery/list.vue'),
      },
      {
        path: 'create',
        name: 'delivery.create',
        meta: {
          permission: 'Create Delivery',
        },
        component: () => import('@/views/dashboard/generated/delivery/create.vue'),
      },
      {
        path: 'trashed',
        name: 'delivery.trashed',
        meta: {
          permission: ['Show Delivery Trash', 'Show Delivery Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/delivery/trashed.vue'),
      },
    ],
  },
]
