export default [
  {
    path: 'backgroundlicense',
    name: 'backgroundlicense',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'backgroundlicense.list',
        meta: {
          permission: ['Backgroundlicense List', 'Backgroundlicense List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/backgroundlicense/list.vue'),
      },
      {
        path: 'create',
        name: 'backgroundlicense.create',
        meta: {
          permission: 'Create Backgroundlicense',
        },
        component: () => import('@/views/dashboard/gtrans/backgroundlicense/create.vue'),
      },
      {
        path: 'edit/:backgroundlicense',
        name: 'backgroundlicense.edit',
        meta: {
          permission: ['Update Backgroundlicense', 'Update Backgroundlicense Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/backgroundlicense/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'backgroundlicense.trashed',
        meta: {
          permission: ['Show Backgroundlicense Trash', 'Show Backgroundlicense Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/backgroundlicense/trashed.vue'),
      },
    ],
  },
]
