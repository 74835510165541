export default [
  {
    path: 'text',
    name: 'text',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'text.list',
        meta: {
          permission: ['Text List', 'Text List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/text/list.vue'),
      },
      {
        path: 'create',
        name: 'text.create',
        meta: {
          permission: 'Create Text',
        },
        component: () => import('@/views/dashboard/gtrans/text/create.vue'),
      },
      {
        path: 'edit/:text',
        name: 'text.edit',
        meta: {
          permission: ['Update Text', 'Update Text Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/text/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'text.trashed',
        meta: {
          permission: ['Show Text Trash', 'Show Text Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/text/trashed.vue'),
      },
    ],
  },
]
