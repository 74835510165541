export default [
  {
    path: 'line',
    name: 'line',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'line.list',
        meta: {
          permission: ['Line List', 'Line List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/line/list.vue'),
      },
      {
        path: 'create',
        name: 'line.create',
        meta: {
          permission: 'Create Line',
        },
        component: () => import('@/views/dashboard/gtrans/line/create.vue'),
      },
      {
        path: 'edit/:line',
        name: 'line.edit',
        meta: {
          permission: ['Update Line', 'Update Line Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/line/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'line.trashed',
        meta: {
          permission: ['Show Line Trash', 'Show Line Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/line/trashed.vue'),
      },
    ],
  },
]
