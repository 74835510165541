export default [
  {
    path: 'tnewhalab',
    name: 'tnewhalab',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'tnewhalab.list',
        meta: {
          permission: ['Tnewhalab List', 'Tnewhalab List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewhalab/list.vue'),
      },
      {
        path: 'create',
        name: 'tnewhalab.create',
        meta: {
          permission: 'Create Tnewhalab',
        },
        component: () => import('@/views/dashboard/gtrans/tnewhalab/create.vue'),
      },
      {
        path: 'edit/:tnewhalab',
        name: 'tnewhalab.edit',
        meta: {
          permission: ['Update Tnewhalab', 'Update Tnewhalab Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewhalab/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'tnewhalab.trashed',
        meta: {
          permission: ['Show Tnewhalab Trash', 'Show Tnewhalab Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewhalab/trashed.vue'),
      },
    ],
  },
]
