export default [
  {
    path: 'halabbackground',
    name: 'halabbackground',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'halabbackground.list',
        meta: {
          permission: ['Halabbackground List', 'Halabbackground List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/halabbackground/list.vue'),
      },
      {
        path: 'create',
        name: 'halabbackground.create',
        meta: {
          permission: 'Create Halabbackground',
        },
        component: () => import('@/views/dashboard/gtrans/halabbackground/create.vue'),
      },
      {
        path: 'edit/:halabbackground',
        name: 'halabbackground.edit',
        meta: {
          permission: ['Update Halabbackground', 'Update Halabbackground Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/halabbackground/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'halabbackground.trashed',
        meta: {
          permission: ['Show Halabbackground Trash', 'Show Halabbackground Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/halabbackground/trashed.vue'),
      },
    ],
  },
]
