export default [
  {
    path: 'idlibmquestion',
    name: 'idlibmquestion',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'idlibmquestion.list',
        meta: {
          permission: ['Idlibmquestion List', 'Idlibmquestion List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/idlibmquestion/list.vue'),
      },
      {
        path: 'create',
        name: 'idlibmquestion.create',
        meta: {
          permission: 'Create Idlibmquestion',
        },
        component: () => import('@/views/dashboard/gtrans/idlibmquestion/create.vue'),
      },
      {
        path: 'edit/:idlibmquestion',
        name: 'idlibmquestion.edit',
        meta: {
          permission: ['Update Idlibmquestion', 'Update Idlibmquestion Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/idlibmquestion/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'idlibmquestion.trashed',
        meta: {
          permission: ['Show Idlibmquestion Trash', 'Show Idlibmquestion Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/idlibmquestion/trashed.vue'),
      },
    ],
  },
]
