export default [
  {
    path: 'company',
    name: 'company',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'company.list',
        meta: {
          permission: ['Company List', 'Company List Owner'],
        },
        component: () => import('@/views/dashboard/generated/company/list.vue'),
      },
      {
        path: 'create',
        name: 'company.create',
        meta: {
          permission: 'Create Company',
        },
        component: () => import('@/views/dashboard/generated/company/create.vue'),
      },
      {
        path: 'edit/:company',
        name: 'company.edit',
        meta: {
          permission: ['Update Company', 'Update Company Owner'],
        },
        component: () => import('@/views/dashboard/generated/company/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'company.trashed',
        meta: {
          permission: ['Show Company Trash', 'Show Company Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/company/trashed.vue'),
      },
    ],
  },
]
