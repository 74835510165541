export default [
  {
    path: 'drdecision',
    name: 'drdecision',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'drdecision.list',
        meta: {
          permission: ['Drtecision List', 'Drtecision List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/drdecision/list.vue'),
      },
      {
        path: 'create',
        name: 'drdecision.create',
        meta: {
          permission: 'Create Drtecision',
        },
        component: () => import('@/views/dashboard/gtrans/drdecision/create.vue'),
      },
      {
        path: 'edit/:drdecision',
        name: 'drdecision.edit',
        meta: {
          permission: ['Update Drtecision', 'Update Drtecision Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/drdecision/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'drdecision.trashed',
        meta: {
          permission: ['Show Drtecision Trash', 'Show Drtecision Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/drdecision/trashed.vue'),
      },
    ],
  },
]
