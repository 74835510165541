export default [
  {
    path: 'achive',
    name: 'achive',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'achive.list',
        meta: {
          permission: ['Achive List', 'Achive List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/achive/list.vue'),
      },
      {
        path: 'create',
        name: 'achive.create',
        meta: {
          permission: 'Create Achive',
        },
        component: () => import('@/views/dashboard/gtrans/achive/create.vue'),
      },
      {
        path: 'edit/:achive',
        name: 'achive.edit',
        meta: {
          permission: ['Update Achive', 'Update Achive Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/achive/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'achive.trashed',
        meta: {
          permission: ['Show Achive Trash', 'Show Achive Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/achive/trashed.vue'),
      },
    ],
  },
]
