export default [
  {
    path: 'user',
    name: 'user',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'user.list',
        meta: {
          permission: ['User List', 'User List Owner'],
        },
        component: () => import('@/views/dashboard/user/list.vue'),
      },
      {
        path: 'trashed',
        name: 'user.trashed',
        meta: {
          permission: ['Show User Trash', 'Show User Trash Owner'],
        },
        component: () => import('@/views/dashboard/user/trashed.vue'),
      },
      {
        path: 'create',
        name: 'user.create',
        meta: {
          permission: 'Create User',
        },
        component: () => import('@/views/dashboard/user/create.vue'),
      },
      {
        path: 'edit/:user',
        name: 'user.edit',
        meta: {
          permission: ['Update User', 'Update User Owner'],
        },
        component: () => import('@/views/dashboard/user/edit.vue'),
      },
    ],
  },
]
