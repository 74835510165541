export default [
  {
    path: 'tnewLicense',
    name: 'tnewLicense',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'tnewLicense.list',
        meta: {
          permission: ['TnewLicense List', 'TnewLicense List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewLicense/list.vue'),
      },
      {
        path: 'create',
        name: 'tnewLicense.create',
        meta: {
          permission: 'Create TnewLicense',
        },
        component: () => import('@/views/dashboard/gtrans/tnewLicense/create.vue'),
      },
      {
        path: 'edit/:tnewLicense',
        name: 'tnewLicense.edit',
        meta: {
          permission: ['Update TnewLicense', 'Update TnewLicense Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewLicense/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'tnewLicense.trashed',
        meta: {
          permission: ['Show TnewLicense Trash', 'Show TnewLicense Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewLicense/trashed.vue'),
      },
    ],
  },
]
