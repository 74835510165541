import User from './user'
import Role from './role'
import Generated from './generated'
import Gtrans from './gtrans'
import Setting from './setting'
import Report from './report'
import Company from './tcompany'
import License from './license'
import Platee from './platee'
import Offense from './offense'
import Owwner from './owwner'
import Carr from './carr'

export default [
  ...User,
  ...Role,
  ...Company,
  ...Carr,
  ...Gtrans,
  ...License,
  ...Offense,
  ...Platee,
  ...Owwner,
  ...Setting,
  ...Generated,
  ...Report,
  {
    path: 'dashboard',
    name: 'dashboard',
    component: () => import('@/views/Home.vue'),
  }, {
    path: 'settings',
    name: 'settings',
    component: () => import('@/views/dashboard/setting/setting.vue'),
  },
]
