export default [
  {
    path: 'tbackground',
    name: 'tbackground',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'tbackground.list',
        meta: {
          permission: ['Tbackground List', 'Tbackground List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tbackground/list.vue'),
      },
      {
        path: 'create',
        name: 'tbackground.create',
        meta: {
          permission: 'Create Tbackground',
        },
        component: () => import('@/views/dashboard/gtrans/tbackground/create.vue'),
      },
      {
        path: 'edit/:tbackground',
        name: 'tbackground.edit',
        meta: {
          permission: ['Update Tbackground', 'Update Tbackground Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tbackground/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'tbackground.trashed',
        meta: {
          permission: ['Show Tbackground Trash', 'Show Tbackground Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tbackground/trashed.vue'),
      },
    ],
  },
]
