export default [
  {
    path: 'slayddriving',
    name: 'slayddriving',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'slayddriving.list',
        meta: {
          permission: ['Slayddriving List', 'Slayddriving List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/slayddriving/list.vue'),
      },
      {
        path: 'create',
        name: 'slayddriving.create',
        meta: {
          permission: 'Create Slayddriving',
        },
        component: () => import('@/views/dashboard/gtrans/slayddriving/create.vue'),
      },
      {
        path: 'edit/:slayddriving',
        name: 'slayddriving.edit',
        meta: {
          permission: ['Update Slayddriving', 'Update Slayddriving Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/slayddriving/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'slayddriving.trashed',
        meta: {
          permission: ['Show Slayddriving Trash', 'Show Slayddriving Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/slayddriving/trashed.vue'),
      },
    ],
  },
]
