// import axios from 'axios'
import Vue from 'vue'
import router from '@/router'

const actions = {

  Login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('authorize/login', payload).then(response => {
        commit('UPDATE_ACCESS_TOKEN', response.data.token)
        commit('UPDATE_USER_INFO', response.data.user)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  Logout({ commit }) {
    Vue.prototype.$http.post('authorize/logout').then(() => {
      commit('LOGOUT', {})
      router.push({
        name: 'login',
      })
    }).catch(() => {
      commit('LOGOUT', {})
      router.push({
        name: 'login',
      })
    })
  },
  UpdateProfile({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('authorize/update_profile', payload).then(response => {
        dispatch('UpdateAuth')
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  ChangePassword({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('authorize/change_password', payload).then(response => {
        dispatch('UpdateAuth')
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  UpdateAuth({ commit, dispatch }) {
    commit('UPDATE_USER_INFO_STATE', localStorage.getItem('user'))
    Vue.prototype.$http.post('authorize/get_auth').then(response => {
      commit('UPDATE_USER_INFO', response.data.user)
    }).catch(() => {
      dispatch('Logout')
    })
  },
}
export default actions
