export default [
  {
    path: 'tcompany',
    name: 'tcompany',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'tcompany.list',
        meta: {
          permission: ['Tcompany List', 'Tcompany List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tcompany/list.vue'),
      },
      {
        path: 'create',
        name: 'tcompany.create',
        meta: {
          permission: 'Create Tcompany',
        },
        component: () => import('@/views/dashboard/gtrans/tcompany/create.vue'),
      },
      {
        path: 'edit/:tcompany',
        name: 'tcompany.edit',
        meta: {
          permission: ['Update Tcompany', 'Update Tcompany Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tcompany/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'tcompany.trashed',
        meta: {
          permission: ['Show Tcompany Trash', 'Show Tcompany Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tcompany/trashed.vue'),
      },
    ],
  },
]
