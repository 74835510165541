export default [
  {
    path: 'services',
    name: 'services',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'services.list',
        meta: {
          permission: ['Services List', 'Services List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/services/list.vue'),
      },
      {
        path: 'create',
        name: 'services.create',
        meta: {
          permission: 'Create Services',
        },
        component: () => import('@/views/dashboard/gtrans/services/create.vue'),
      },
      {
        path: 'edit/:services',
        name: 'services.edit',
        meta: {
          permission: ['Update Services', 'Update Services Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/services/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'services.trashed',
        meta: {
          permission: ['Show Services Trash', 'Show Services Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/services/trashed.vue'),
      },
    ],
  },
]
