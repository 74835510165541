export default [
  {
    path: 'statistic',
    name: 'statistic',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'statistic.list',
        meta: {
          permission: ['Statistic List', 'Statistic List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/statistic/list.vue'),
      },
      {
        path: 'create',
        name: 'statistic.create',
        meta: {
          permission: 'Create Statistic',
        },
        component: () => import('@/views/dashboard/gtrans/statistic/create.vue'),
      },
      {
        path: 'edit/:statistic',
        name: 'statistic.edit',
        meta: {
          permission: ['Update Statistic', 'Update Statistic Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/statistic/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'statistic.trashed',
        meta: {
          permission: ['Show Statistic Trash', 'Show Statistic Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/statistic/trashed.vue'),
      },
    ],
  },
]
