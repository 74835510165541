export default [

  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
    },
    beforeEnter(to, _, next) {
      if (localStorage.getItem('isAuth')) {
        next({ name: 'dashboard' })
      } else {
        next()// not auth
      }
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/auth/profile.vue'),
  },

]
