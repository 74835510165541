export default [
  {
    path: 'ibroker',
    name: 'ibroker',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'ibroker.list',
        meta: {
          permission: ['Ibroker List', 'Ibroker List Owner'],
        },
        component: () => import('@/views/dashboard/generated/ibroker/list.vue'),
      },
      {
        path: 'create',
        name: 'ibroker.create',
        meta: {
          permission: 'Create Ibroker',
        },
        component: () => import('@/views/dashboard/generated/ibroker/create.vue'),
      },
      {
        path: 'edit/:ibroker',
        name: 'ibroker.edit',
        meta: {
          permission: ['Update Ibroker', 'Update Ibroker Owner'],
        },
        component: () => import('@/views/dashboard/generated/ibroker/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'ibroker.trashed',
        meta: {
          permission: ['Show Ibroker Trash', 'Show Ibroker Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/ibroker/trashed.vue'),
      },
    ],
  },
]
