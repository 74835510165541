export default [
  {
    path: 'jsrbackground',
    name: 'jsrbackground',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'jsrbackground.list',
        meta: {
          permission: ['Jsrbackground List', 'Jsrbackground List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/jsrbackground/list.vue'),
      },
      {
        path: 'create',
        name: 'jsrbackground.create',
        meta: {
          permission: 'Create Jsrbackground',
        },
        component: () => import('@/views/dashboard/gtrans/jsrbackground/create.vue'),
      },
      {
        path: 'edit/:jsrbackground',
        name: 'jsrbackground.edit',
        meta: {
          permission: ['Update Jsrbackground', 'Update Jsrbackground Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/jsrbackground/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'jsrbackground.trashed',
        meta: {
          permission: ['Show Jsrbackground Trash', 'Show Jsrbackground Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/jsrbackground/trashed.vue'),
      },
    ],
  },
]
