export default [
  {
    path: 'drnew',
    name: 'drnew',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'drnew.list',
        meta: {
          permission: ['Drnew List', 'Drnew List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/drnew/list.vue'),
      },
      {
        path: 'create',
        name: 'drnew.create',
        meta: {
          permission: 'Create Drnew',
        },
        component: () => import('@/views/dashboard/gtrans/drnew/create.vue'),
      },
      {
        path: 'edit/:drnew',
        name: 'drnew.edit',
        meta: {
          permission: ['Update Drnew', 'Update Drnew Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/drnew/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'drnew.trashed',
        meta: {
          permission: ['Show Drnew Trash', 'Show Drnew Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/drnew/trashed.vue'),
      },
    ],
  },
]
