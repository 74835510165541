export default [
  {
    path: 'role',
    name: 'role',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'role.list',
        meta: {
          permission: 'Role List',
        },
        component: () => import('@/views/dashboard/role/list.vue'),
      },
    ],
  },
]
