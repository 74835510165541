export default [
  {
    path: 'halabstatistic',
    name: 'halabstatistic',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'halabstatistic.list',
        meta: {
          permission: ['Halabstatistic List', 'Halabstatistic List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/halabstatistic/list.vue'),
      },
      {
        path: 'create',
        name: 'halabstatistic.create',
        meta: {
          permission: 'Create Halabstatistic',
        },
        component: () => import('@/views/dashboard/gtrans/halabstatistic/create.vue'),
      },
      {
        path: 'edit/:halabstatistic',
        name: 'halabstatistic.edit',
        meta: {
          permission: ['Update Halabstatistic', 'Update Halabstatistic Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/halabstatistic/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'halabstatistic.trashed',
        meta: {
          permission: ['Show Halabstatistic Trash', 'Show Halabstatistic Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/halabstatistic/trashed.vue'),
      },
    ],
  },
]
