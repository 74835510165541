export default [
  {
    path: 'idlibdecision',
    name: 'idlibdecision',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'idlibdecision.list',
        meta: {
          permission: ['Idlibdecision List', 'Idlibdecision List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/idlibdecision/list.vue'),
      },
      {
        path: 'create',
        name: 'idlibdecision.create',
        meta: {
          permission: 'Create Idlibdecision',
        },
        component: () => import('@/views/dashboard/gtrans/idlibdecision/create.vue'),
      },
      {
        path: 'edit/:idlibdecision',
        name: 'idlibdecision.edit',
        meta: {
          permission: ['Update Idlibdecision', 'Update Idlibdecision Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/idlibdecision/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'idlibdecision.trashed',
        meta: {
          permission: ['Show Idlibdecision Trash', 'Show Idlibdecision Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/idlibdecision/trashed.vue'),
      },
    ],
  },
]
