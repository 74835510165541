export default [
  {
    path: 'plate',
    name: 'plate',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'plate.list',
        meta: {
          permission: ['Plate List', 'Plate List Owner'],
        },
        component: () => import('@/views/dashboard/generated/plate/list.vue'),
      },
      {
        path: 'platesMulti',
        name: 'plate.platesMulti',
        meta: {
          permission: 'Create Plate',
        },
        component: () => import('@/views/dashboard/generated/plate/platesMulti.vue'),
      },
      {
        path: 'create',
        name: 'plate.create',
        meta: {
          permission: 'Create Plate',
        },
        component: () => import('@/views/dashboard/generated/plate/create.vue'),
      },
      {
        path: 'edit/:plate',
        name: 'plate.edit',
        meta: {
          permission: ['Update Plate', 'Update Plate Owner'],
        },
        component: () => import('@/views/dashboard/generated/plate/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'plate.trashed',
        meta: {
          permission: ['Show Plate Trash', 'Show Plate Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/plate/trashed.vue'),
      },
    ],
  },
]
