export default [
  {
    path: 'owwner',
    name: 'owwner',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'owwner.list',
        meta: {
          permission: ['Owwner List', 'Owwner List Owner'],
        },
        component: () => import('@/views/dashboard/generated/owwner/list.vue'),
      },
      {
        path: 'create',
        name: 'owwner.create',
        meta: {
          permission: 'Create Owwner',
        },
        component: () => import('@/views/dashboard/generated/owwner/create.vue'),
      },
      {
        path: 'edit/:owwner',
        name: 'owwner.edit',
        meta: {
          permission: ['Update Owwner', 'Update Owwner Owner'],
        },
        component: () => import('@/views/dashboard/generated/owwner/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'owwner.trashed',
        meta: {
          permission: ['Show Owwner Trash', 'Show Owwner Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/owwner/trashed.vue'),
      },
    ],
  },
]
