export default [
  {
    path: 'halabdecision',
    name: 'halabdecision',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'halabdecision.list',
        meta: {
          permission: ['Halabdecision List', 'Halabdecision List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/halabdecision/list.vue'),
      },
      {
        path: 'create',
        name: 'halabdecision.create',
        meta: {
          permission: 'Create Halabdecision',
        },
        component: () => import('@/views/dashboard/gtrans/halabdecision/create.vue'),
      },
      {
        path: 'edit/:halabdecision',
        name: 'halabdecision.edit',
        meta: {
          permission: ['Update Halabdecision', 'Update Halabdecision Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/halabdecision/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'halabdecision.trashed',
        meta: {
          permission: ['Show Halabdecision Trash', 'Show Halabdecision Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/halabdecision/trashed.vue'),
      },
    ],
  },
]
