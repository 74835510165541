export default [
  {
    path: 'platee',
    name: 'platee',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'platee.list',
        meta: {
          permission: ['Platee List'],
        },
        component: () => import('@/views/dashboard/generated/platee/list.vue'),
      },
      {
        path: 'create',
        name: 'platee.create',
        meta: {
          permission: 'Create Platee',
        },
        component: () => import('@/views/dashboard/generated/platee/create.vue'),
      },
      {
        path: 'edit/:platee',
        name: 'platee.edit',
        meta: {
          permission: ['Update Platee', 'Update Platee Owner'],
        },
        component: () => import('@/views/dashboard/generated/platee/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'platee.trashed',
        meta: {
          permission: ['Show Platee Trash', 'Show Platee Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/platee/trashed.vue'),
      },
    ],
  },
]
