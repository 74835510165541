export default [
  {
    path: 'connecthalab',
    name: 'connecthalab',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'connecthalab.list',
        meta: {
          permission: ['Connecthalab List', 'Connecthalab List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/connecthalab/list.vue'),
      },
      {
        path: 'create',
        name: 'connecthalab.create',
        meta: {
          permission: 'Create Connecthalab',
        },
        component: () => import('@/views/dashboard/gtrans/connecthalab/create.vue'),
      },
      {
        path: 'edit/:connecthalab',
        name: 'connecthalab.edit',
        meta: {
          permission: ['Update Connecthalab', 'Update Connecthalab Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/connecthalab/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'connecthalab.trashed',
        meta: {
          permission: ['Show Connecthalab Trash', 'Show Connecthalab Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/connecthalab/trashed.vue'),
      },
    ],
  },
]
