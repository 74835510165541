export default [
  {
    path: 'karaj',
    name: 'karaj',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'karaj.list',
        meta: {
          permission: ['Karaj List', 'Karaj List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/karaj/list.vue'),
      },
      {
        path: 'create',
        name: 'karaj.create',
        meta: {
          permission: 'Create Karaj',
        },
        component: () => import('@/views/dashboard/gtrans/karaj/create.vue'),
      },
      {
        path: 'edit/:karaj',
        name: 'karaj.edit',
        meta: {
          permission: ['Update Karaj', 'Update Karaj Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/karaj/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'karaj.trashed',
        meta: {
          permission: ['Show Karaj Trash', 'Show Karaj Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/karaj/trashed.vue'),
      },
    ],
  },
]
