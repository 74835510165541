import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import Upload from '@/layouts/components/Upload.vue'
import Explain from '@/views/dashboard/components/explain.vue'

Vue.prototype.moment = moment
library.add(fas)
library.add(fab)
library.add(far)
Vue.component('explain', Explain)
Vue.component('upload', Upload)
Vue.component('cleave', Cleave)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-select', vSelect)
Vue.component(quillEditor.name, quillEditor)
Vue.component(FeatherIcon.name, FeatherIcon)
