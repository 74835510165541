export default [
  {
    path: 'checkin',
    name: 'checkin',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'checkin.list',
        meta: {
          permission: ['Checkin List', 'Checkin List Owner'],
        },
        component: () => import('@/views/dashboard/generated/checkin/list.vue'),
      },
      {
        path: 'create',
        name: 'checkin.create',
        meta: {
          permission: 'Create Checkin',
        },
        component: () => import('@/views/dashboard/generated/checkin/create.vue'),
      },
      {
        path: 'edit/:checkin',
        name: 'checkin.edit',
        meta: {
          permission: ['Update Checkin', 'Update Checkin Owner'],
        },
        component: () => import('@/views/dashboard/generated/checkin/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'checkin.trashed',
        meta: {
          permission: ['Show Checkin Trash', 'Show Checkin Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/checkin/trashed.vue'),
      },
    ],
  },
]
