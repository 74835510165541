export default [
  {
    path: 'idlibbackground',
    name: 'idlibbackground',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'idlibbackground.list',
        meta: {
          permission: ['Idlibbackground List', 'Idlibbackground List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/idlibbackground/list.vue'),
      },
      {
        path: 'create',
        name: 'idlibbackground.create',
        meta: {
          permission: 'Create Idlibbackground',
        },
        component: () => import('@/views/dashboard/gtrans/idlibbackground/create.vue'),
      },
      {
        path: 'edit/:idlibbackground',
        name: 'idlibbackground.edit',
        meta: {
          permission: ['Update Idlibbackground', 'Update Idlibbackground Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/idlibbackground/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'idlibbackground.trashed',
        meta: {
          permission: ['Show Idlibbackground Trash', 'Show Idlibbackground Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/idlibbackground/trashed.vue'),
      },
    ],
  },
]
