export default [
  {
    path: 'jsrdecision',
    name: 'jsrdecision',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'jsrdecision.list',
        meta: {
          permission: ['Jsrdecision List', 'Jsrdecision List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/jsrdecision/list.vue'),
      },
      {
        path: 'create',
        name: 'jsrdecision.create',
        meta: {
          permission: 'Create Jsrdecision',
        },
        component: () => import('@/views/dashboard/gtrans/jsrdecision/create.vue'),
      },
      {
        path: 'edit/:jsrdecision',
        name: 'jsrdecision.edit',
        meta: {
          permission: ['Update Jsrdecision', 'Update Jsrdecision Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/jsrdecision/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'jsrdecision.trashed',
        meta: {
          permission: ['Show Jsrdecision Trash', 'Show Jsrdecision Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/jsrdecision/trashed.vue'),
      },
    ],
  },
]
