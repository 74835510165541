import Ibroker from './ibroker'
import Owner from './owner'
import Car from './car'
import Plate from './plate'
import Fee from './fee'
import Check from './check'
import Fundd from './fundd'
import Renew from './renew'
import Pay from './pay'
import Checkk from './checkk'
import Fund from './fund'
import Company from './company'
import Sale from './sale'
import Toll from './toll'
import Record from './record'
import Reciept from './reciept'
import Carr from './carr'
import Offense from './offense'
import Old from './old'
import Owwner from './owwner'
import Platee from './platee'
import Test from './test'
import Checkin from './checkin'
import Delivery from './delivery'
import Ebroker from './ebroker'

export default [
  ...Ibroker,
  ...Old,
  ...Delivery,
  ...Fund,
  ...Fundd,
  ...Record,
  ...Reciept,
  ...Pay,
  ...Owner,
  ...Offense,
  ...Test,
  ...Sale,
  ...Renew,
  ...Toll,
  ...Owwner,
  ...Platee,
  ...Carr,
  ...Company,
  ...Car,
  ...Plate,
  ...Fee,
  ...Check,
  ...Checkk,
  ...Checkin,
  ...Ebroker,
]
