export default [
  {
    path: 'videoidlib',
    name: 'videoidlib',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'videoidlib.list',
        meta: {
          permission: ['Videoidlib List', 'Videoidlib List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videoidlib/list.vue'),
      },
      {
        path: 'create',
        name: 'videoidlib.create',
        meta: {
          permission: 'Create Videoidlib',
        },
        component: () => import('@/views/dashboard/gtrans/videoidlib/create.vue'),
      },
      {
        path: 'edit/:videoidlib',
        name: 'videoidlib.edit',
        meta: {
          permission: ['Update Videoidlib', 'Update Videoidlib Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videoidlib/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'videoidlib.trashed',
        meta: {
          permission: ['Show Videoidlib Trash', 'Show Videoidlib Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videoidlib/trashed.vue'),
      },
    ],
  },
]
