export default [
  {
    path: 'decision',
    name: 'decision',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'decision.list',
        meta: {
          permission: ['Decision List', 'Decision List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/decision/list.vue'),
      },
      {
        path: 'create',
        name: 'decision.create',
        meta: {
          permission: 'Create Decision',
        },
        component: () => import('@/views/dashboard/gtrans/decision/create.vue'),
      },
      {
        path: 'edit/:decision',
        name: 'decision.edit',
        meta: {
          permission: ['Update Decision', 'Update Decision Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/decision/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'decision.trashed',
        meta: {
          permission: ['Show Decision Trash', 'Show Decision Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/decision/trashed.vue'),
      },
    ],
  },
]
