export default [
  {
    path: 'ttnew',
    name: 'ttnew',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'ttnew.list',
        meta: {
          permission: ['Ttnew List', 'Ttnew List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/ttnew/list.vue'),
      },
      {
        path: 'create',
        name: 'ttnew.create',
        meta: {
          permission: 'Create Ttnew',
        },
        component: () => import('@/views/dashboard/gtrans/ttnew/create.vue'),
      },
      {
        path: 'edit/:ttnew',
        name: 'ttnew.edit',
        meta: {
          permission: ['Update Ttnew', 'Update Ttnew Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/ttnew/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'ttnew.trashed',
        meta: {
          permission: ['Show Ttnew Trash', 'Show Ttnew Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/ttnew/trashed.vue'),
      },
    ],
  },
]
