export default [
  {
    path: 'harembackground',
    name: 'harembackground',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'harembackground.list',
        meta: {
          permission: ['Harembackground List', 'Harembackground List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/harembackground/list.vue'),
      },
      {
        path: 'create',
        name: 'harembackground.create',
        meta: {
          permission: 'Create Harembackground',
        },
        component: () => import('@/views/dashboard/gtrans/harembackground/create.vue'),
      },
      {
        path: 'edit/:harembackground',
        name: 'harembackground.edit',
        meta: {
          permission: ['Update Harembackground', 'Update Harembackground Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/harembackground/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'harembackground.trashed',
        meta: {
          permission: ['Show Harembackground Trash', 'Show Harembackground Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/harembackground/trashed.vue'),
      },
    ],
  },
]
