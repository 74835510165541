export default [
  {
    path: 'record',
    name: 'record',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'invoice/:check',
        name: 'record.invoice',
        meta: {
          permission: ['Checkk Invoice'],
        },
        component: () => import('@/views/dashboard/generated/record/invoice.vue'),
      },
      {
        path: 'certificate/:record',
        name: 'record.certificate',
        meta: {
          permission: ['Checkk Certificate'],
        },
        component: () => import('@/views/dashboard/generated/record/certificate.vue'),
      },
      {
        path: 'edit/:record',
        name: 'record.edit',
        meta: {
          permission: ['Update Checkk', 'Update Checkk Owner'],
        },
        component: () => import('@/views/dashboard/generated/record/edit.vue'),
      },
      {
        path: 'list',
        name: 'record.list',
        meta: {
          permission: ['Checkk List', 'Checkk List Owner'],
        },
        component: () => import('@/views/dashboard/generated/record/list.vue'),
      },
      {
        path: 'create',
        name: 'record.create',
        meta: {
          permission: 'Create Checkk',
        },
        component: () => import('@/views/dashboard/generated/record/create.vue'),
      },
      {
        path: 'trashed',
        name: 'record.trashed',
        meta: {
          permission: ['Show Checkk Trash', 'Show Checkk Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/record/trashed.vue'),
      },
    ],
  },
]
