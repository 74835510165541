export default [
  {
    path: 'complaints',
    name: 'complaints',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'complaints.list',
        meta: {
          permission: ['Complaints List', 'Complaints List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/complaints/list.vue'),
      },
      {
        path: 'create',
        name: 'complaints.create',
        meta: {
          permission: 'Create Complaints',
        },
        component: () => import('@/views/dashboard/gtrans/complaints/create.vue'),
      },
      {
        path: 'edit/:complaints',
        name: 'complaints.edit',
        meta: {
          permission: ['Update Complaints', 'Update Complaints Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/complaints/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'complaints.trashed',
        meta: {
          permission: ['Show Complaints Trash', 'Show Complaints Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/complaints/trashed.vue'),
      },
    ],
  },
]
