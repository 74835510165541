export default [
  {
    path: 'tnewharem',
    name: 'tnewharem',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'tnewharem.list',
        meta: {
          permission: ['Tnewharem List', 'Tnewharem List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewharem/list.vue'),
      },
      {
        path: 'create',
        name: 'tnewharem.create',
        meta: {
          permission: 'Create Tnewharem',
        },
        component: () => import('@/views/dashboard/gtrans/tnewharem/create.vue'),
      },
      {
        path: 'edit/:tnewharem',
        name: 'tnewharem.edit',
        meta: {
          permission: ['Update Tnewharem', 'Update Tnewharem Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewharem/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'tnewharem.trashed',
        meta: {
          permission: ['Show Tnewharem Trash', 'Show Tnewharem Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewharem/trashed.vue'),
      },
    ],
  },
]
