export default [
  {
    path: 'employee',
    name: 'employee',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'employee.list',
        meta: {
          permission: ['Employee List', 'Employee List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/employee/list.vue'),
      },
      {
        path: 'create',
        name: 'employee.create',
        meta: {
          permission: 'Create Employee',
        },
        component: () => import('@/views/dashboard/gtrans/employee/create.vue'),
      },
      {
        path: 'edit/:employee',
        name: 'employee.edit',
        meta: {
          permission: ['Update Employee', 'Update Employee Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/employee/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'employee.trashed',
        meta: {
          permission: ['Show Employee Trash', 'Show Employee Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/employee/trashed.vue'),
      },
    ],
  },
]
