export default [
  {
    path: 'connect',
    name: 'connect',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'connect.list',
        meta: {
          permission: ['Connect List', 'Connect List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/connect/list.vue'),
      },
      {
        path: 'create',
        name: 'connect.create',
        meta: {
          permission: 'Create Connect',
        },
        component: () => import('@/views/dashboard/gtrans/connect/create.vue'),
      },
      {
        path: 'edit/:connect',
        name: 'connect.edit',
        meta: {
          permission: ['Update Connect', 'Update Connect Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/connect/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'connect.trashed',
        meta: {
          permission: ['Show Connect Trash', 'Show Connect Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/connect/trashed.vue'),
      },
    ],
  },
]
