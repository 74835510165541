import role from './role'
import setting from './setting'
import user from './user'
import generated from './generated'
import gtrans from './gtrans'

export default {
  modules: {
    user,
    role,
    setting,
    generated,
    gtrans,
  },
}
