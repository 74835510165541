export default [
  {
    path: 'car',
    name: 'car',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'car.list',
        meta: {
          permission: ['Car List', 'Car List Owner'],
        },
        component: () => import('@/views/dashboard/generated/car/list.vue'),
      },
      {
        path: 'create',
        name: 'car.create',
        meta: {
          permission: 'Create Car',
        },
        component: () => import('@/views/dashboard/generated/car/create.vue'),
      },
      {
        path: 'edit/:car',
        name: 'car.edit',
        meta: {
          permission: ['Update Car', 'Update Car Owner'],
        },
        component: () => import('@/views/dashboard/generated/car/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'car.trashed',
        meta: {
          permission: ['Show Car Trash', 'Show Car Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/car/trashed.vue'),
      },
    ],
  },
]
