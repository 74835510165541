export default [
  {
    path: 'preview',
    name: 'preview',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'preview.list',
        meta: {
          permission: ['Preview List', 'Preview List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/preview/list.vue'),
      },
      {
        path: 'create',
        name: 'preview.create',
        meta: {
          permission: 'Create Preview',
        },
        component: () => import('@/views/dashboard/gtrans/preview/create.vue'),
      },
      {
        path: 'edit/:preview',
        name: 'preview.edit',
        meta: {
          permission: ['Update Preview', 'Update Preview Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/preview/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'preview.trashed',
        meta: {
          permission: ['Show Preview Trash', 'Show Preview Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/preview/trashed.vue'),
      },
    ],
  },
]
