export default [
  {
    path: 'achiveLicensecondition',
    name: 'achiveLicensecondition',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'achiveLicensecondition.list',
        meta: {
          permission: ['AchiveLicensecondition List', 'AchiveLicensecondition List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/achiveLicensecondition/list.vue'),
      },
      {
        path: 'create',
        name: 'achiveLicensecondition.create',
        meta: {
          permission: 'Create AchiveLicensecondition',
        },
        component: () => import('@/views/dashboard/gtrans/achiveLicensecondition/create.vue'),
      },
      {
        path: 'edit/:achiveLicensecondition',
        name: 'achiveLicensecondition.edit',
        meta: {
          permission: ['Update AchiveLicensecondition', 'Update AchiveLicensecondition Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/achiveLicensecondition/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'achiveLicensecondition.trashed',
        meta: {
          permission: ['Show AchiveLicensecondition Trash', 'Show AchiveLicensecondition Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/achiveLicensecondition/trashed.vue'),
      },
    ],
  },
]
