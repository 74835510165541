import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    element: {
      OwnersThrough: [],
      plate_fees: [],
      renew_fees: [],
      pay_fees: [],
      other_fees: [],
      cars: [],
      ebrokers: [],
      ibrokers: [],
      owners: [],
      pay: [],
    },
    elements: [],
  },
  getters: {
    GetElement: state => state.element,
    GetElements: state => state.elements,
  },
  mutations: {
    GET_ELEMENT_DATA(state, value) {
      state.element = value
    },
    GET_ELEMENTS_DATA(state, value) {
      state.elements = value
    },
  },
  actions: {
    GetElement({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.get(`report/${payload.date}`).then(response => {
          commit('GET_ELEMENT_DATA', response.data.reports)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
