export default [
  {
    path: 'ebroker',
    name: 'ebroker',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'ebroker.list',
        meta: {
          permission: ['Ebroker List', 'Ebroker List Owner'],
        },
        component: () => import('@/views/dashboard/generated/ebroker/list.vue'),
      },
      {
        path: 'create',
        name: 'ebroker.create',
        meta: {
          permission: 'Create Ebroker',
        },
        component: () => import('@/views/dashboard/generated/ebroker/create.vue'),
      },
      {
        path: 'edit/:ebroker',
        name: 'ebroker.edit',
        meta: {
          permission: ['Update Ebroker', 'Update Ebroker Owner'],
        },
        component: () => import('@/views/dashboard/generated/ebroker/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'ebroker.trashed',
        meta: {
          permission: ['Show Ebroker Trash', 'Show Ebroker Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/ebroker/trashed.vue'),
      },
    ],
  },
]
