export default [
  {
    path: 'decisionCertificate',
    name: 'decisionCertificate',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'decisionCertificate.list',
        meta: {
          permission: ['DecisionCertificate List', 'DecisionCertificate List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/decisionCertificate/list.vue'),
      },
      {
        path: 'create',
        name: 'decisionCertificate.create',
        meta: {
          permission: 'Create DecisionCertificate',
        },
        component: () => import('@/views/dashboard/gtrans/decisionCertificate/create.vue'),
      },
      {
        path: 'edit/:decisionCertificate',
        name: 'decisionCertificate.edit',
        meta: {
          permission: ['Update DecisionCertificate', 'Update DecisionCertificate Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/decisionCertificate/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'decisionCertificate.trashed',
        meta: {
          permission: ['Show DecisionCertificate Trash', 'Show DecisionCertificate Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/decisionCertificate/trashed.vue'),
      },
    ],
  },
]
