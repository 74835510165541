import ibroker from './ibroker'
import owner from './owner'
import car from './car'
import gtrans from './gtrans'
import plate from './plate'
import fee from './fee'
import check from './check'
import license from './license'
import pay from './pay'
import fund from './fund'
import fundd from './fundd'
import checkk from './checkk'
import renew from './renew'
import checkin from './checkin'
import ebroker from './ebroker'
import old from './old'
import report from './report'
import company from './company'
import offense from './offense'
import reciept from './reciept'
import test from './test'
import toll from './toll'
import platee from './platee'
import delivery from './delivery'
import carr from './carr'
import sale from './sale'
import owwner from './owwner'

export default {
  modules: {
    ibroker,
    owner,
    gtrans,
    pay,
    fund,
    fundd,
    reciept,
    old,
    delivery,
    license,
    renew,
    test,
    car,
    company,
    toll,
    sale,
    offense,
    carr,
    owwner,
    platee,
    plate,
    fee,
    check,
    checkk,
    checkin,
    ebroker,
    report,
  },
}
