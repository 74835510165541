export default [
  {
    path: 'school',
    name: 'school',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'school.list',
        meta: {
          permission: ['School List', 'School List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/school/list.vue'),
      },
      {
        path: 'create',
        name: 'school.create',
        meta: {
          permission: 'Create School',
        },
        component: () => import('@/views/dashboard/gtrans/school/create.vue'),
      },
      {
        path: 'edit/:school',
        name: 'school.edit',
        meta: {
          permission: ['Update School', 'Update School Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/school/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'school.trashed',
        meta: {
          permission: ['Show School Trash', 'Show School Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/school/trashed.vue'),
      },
    ],
  },
]
