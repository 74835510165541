export default [
  {
    path: 'theory_test',
    name: 'theory_test',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'theoryTest.list',
        meta: {
          permission: ['Theory_test List', 'Theory_test List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/theoryTest/list.vue'),
      },
      {
        path: 'create',
        name: 'theoryTest.create',
        meta: {
          permission: 'Create Theory_test',
        },
        component: () => import('@/views/dashboard/gtrans/theoryTest/create.vue'),
      },
      {
        path: 'edit/:theoryTest',
        name: 'theoryTest.edit',
        meta: {
          permission: ['Update Theory_test', 'Update Theory_test Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/theoryTest/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'theoryTest.trashed',
        meta: {
          permission: ['Show Theory_test Trash', 'Show Theory_test Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/theoryTest/trashed.vue'),
      },
    ],
  },
]
