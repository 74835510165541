export default [
  {
    path: 'owwner',
    name: 'owwner',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'owwner.list',
        meta: {
          permission: ['owwner List', 'Owwner List Owner'],
        },
        component: () => import('@/views/dashboard/generated/owwner/list.vue'),
      },
    ],
  },
]
