export default [
  {
    path: 'owner',
    name: 'owner',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'owner.list',
        meta: {
          permission: ['Owner List', 'Owner List Owner'],
        },
        component: () => import('@/views/dashboard/generated/owner/list.vue'),
      },
      {
        path: 'create',
        name: 'owner.create',
        meta: {
          permission: 'Create Owner',
        },
        component: () => import('@/views/dashboard/generated/owner/create.vue'),
      },
      {
        path: 'edit/:owner',
        name: 'owner.edit',
        meta: {
          permission: ['Update Owner', 'Update Owner Owner'],
        },
        component: () => import('@/views/dashboard/generated/owner/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'owner.trashed',
        meta: {
          permission: ['Show Owner Trash', 'Show Owner Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/owner/trashed.vue'),
      },
    ],
  },
]
