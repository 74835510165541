export default [
  {
    path: 'sicon',
    name: 'sicon',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'sicon.list',
        meta: {
          permission: ['Sicon List', 'Sicon List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/sicon/list.vue'),
      },
      {
        path: 'create',
        name: 'sicon.create',
        meta: {
          permission: 'Create Sicon',
        },
        component: () => import('@/views/dashboard/gtrans/sicon/create.vue'),
      },
      {
        path: 'edit/:sicon',
        name: 'sicon.edit',
        meta: {
          permission: ['Update Sicon', 'Update Sicon Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/sicon/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'sicon.trashed',
        meta: {
          permission: ['Show Sicon Trash', 'Show Sicon Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/sicon/trashed.vue'),
      },
    ],
  },
]
