export default [
  {
    path: 'license',
    name: 'license',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'license.list',
        meta: {
          permission: ['License List', 'License List Owner'],
        },
        component: () => import('@/views/dashboard/generated/license/list.vue'),
      },
      {
        path: 'create',
        name: 'license.create',
        meta: {
          permission: 'Create License',
        },
        component: () => import('@/views/dashboard/generated/license/create.vue'),
      },
      {
        path: 'edit/:license',
        name: 'license.edit',
        meta: {
          permission: ['Update License', 'Update License Owner'],
        },
        component: () => import('@/views/dashboard/generated/license/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'license.trashed',
        meta: {
          permission: ['Show License Trash', 'Show License Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/license/trashed.vue'),
      },
    ],
  },
]
