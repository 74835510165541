export default [
  {
    path: 'checkk',
    name: 'checkk',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'invoice/:check',
        name: 'checkk.invoice',
        meta: {
          permission: ['Checkk Invoice'],
        },
        component: () => import('@/views/dashboard/generated/checkk/invoice.vue'),
      },
      {
        path: 'certificate/:checkk',
        name: 'checkk.certificate',
        meta: {
          permission: ['Checkk Certificate'],
        },
        component: () => import('@/views/dashboard/generated/checkk/certificate.vue'),
      },
      {
        path: 'edit/:checkk',
        name: 'checkk.edit',
        meta: {
          permission: ['Update Checkk', 'Update Checkk Owner'],
        },
        component: () => import('@/views/dashboard/generated/checkk/edit.vue'),
      },
      {
        path: 'list',
        name: 'checkk.list',
        meta: {
          permission: ['Checkk List', 'Checkk List Owner'],
        },
        component: () => import('@/views/dashboard/generated/checkk/list.vue'),
      },
      {
        path: 'create',
        name: 'checkk.create',
        meta: {
          permission: 'Create Checkk',
        },
        component: () => import('@/views/dashboard/generated/checkk/create.vue'),
      },
      {
        path: 'trashed',
        name: 'checkk.trashed',
        meta: {
          permission: ['Show Checkk Trash', 'Show Checkk Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/checkk/trashed.vue'),
      },
    ],
  },
]
