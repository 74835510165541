export default [
  {
    path: 'slaydmain',
    name: 'slaydmain',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'slaydmain.list',
        meta: {
          permission: ['Slaydmain List', 'Slaydmain List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/slaydmain/list.vue'),
      },
      {
        path: 'create',
        name: 'slaydmain.create',
        meta: {
          permission: 'Create Slaydmain',
        },
        component: () => import('@/views/dashboard/gtrans/slaydmain/create.vue'),
      },
      {
        path: 'edit/:slaydmain',
        name: 'slaydmain.edit',
        meta: {
          permission: ['Update Slaydmain', 'Update Slaydmain Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/slaydmain/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'slaydmain.trashed',
        meta: {
          permission: ['Show Slaydmain Trash', 'Show Slaydmain Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/slaydmain/trashed.vue'),
      },
    ],
  },
]
