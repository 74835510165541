export default [
  {
    path: 'galleryidlib',
    name: 'galleryidlib',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'galleryidlib.list',
        meta: {
          permission: ['Galleryidlib List', 'Galleryidlib List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/galleryidlib/list.vue'),
      },
      {
        path: 'create',
        name: 'galleryidlib.create',
        meta: {
          permission: 'Create Galleryidlib',
        },
        component: () => import('@/views/dashboard/gtrans/galleryidlib/create.vue'),
      },
      {
        path: 'edit/:galleryidlib',
        name: 'galleryidlib.edit',
        meta: {
          permission: ['Update Galleryidlib', 'Update Galleryidlib Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/galleryidlib/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'galleryidlib.trashed',
        meta: {
          permission: ['Show Galleryidlib Trash', 'Show Galleryidlib Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/galleryidlib/trashed.vue'),
      },
    ],
  },
]
