export default [
  {
    path: 'tnewPublictransport',
    name: 'tnewPublictransport',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'tnewPublictransport.list',
        meta: {
          permission: ['TnewPublictransport List', 'TnewPublictransport List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewPublictransport/list.vue'),
      },
      {
        path: 'create',
        name: 'tnewPublictransport.create',
        meta: {
          permission: 'Create TnewPublictransport',
        },
        component: () => import('@/views/dashboard/gtrans/tnewPublictransport/create.vue'),
      },
      {
        path: 'edit/:tnewPublictransport',
        name: 'tnewPublictransport.edit',
        meta: {
          permission: ['Update TnewPublictransport', 'Update TnewPublictransport Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewPublictransport/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'tnewPublictransport.trashed',
        meta: {
          permission: ['Show TnewPublictransport Trash', 'Show TnewPublictransport Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewPublictransport/trashed.vue'),
      },
    ],
  },
]
