export default [
  {
    path: 'tgallery',
    name: 'tgallery',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'tgallery.list',
        meta: {
          permission: ['Tgallery List', 'Tgallery List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tgallery/list.vue'),
      },
      {
        path: 'create',
        name: 'tgallery.create',
        meta: {
          permission: 'Create Tgallery',
        },
        component: () => import('@/views/dashboard/gtrans/tgallery/create.vue'),
      },
      {
        path: 'edit/:tgallery',
        name: 'tgallery.edit',
        meta: {
          permission: ['Update Tgallery', 'Update Tgallery Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tgallery/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'tgallery.trashed',
        meta: {
          permission: ['Show Tgallery Trash', 'Show Tgallery Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tgallery/trashed.vue'),
      },
    ],
  },
]
