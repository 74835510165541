export default [
  {
    path: 'offense',
    name: 'offense',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'offense.list',
        meta: {
          permission: ['Offense List', 'Offense List Owner'],
        },
        component: () => import('@/views/dashboard/generated/offense/list.vue'),
      },
      {
        path: 'create',
        name: 'offense.create',
        meta: {
          permission: 'Create Offense',
        },
        component: () => import('@/views/dashboard/generated/offense/create.vue'),
      },
      {
        path: 'edit/:offense',
        name: 'offense.edit',
        meta: {
          permission: ['Update Offense', 'Update Offense Owner'],
        },
        component: () => import('@/views/dashboard/generated/offense/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'offense.trashed',
        meta: {
          permission: ['Show Offense Trash', 'Show Offense Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/offense/trashed.vue'),
      },
    ],
  },
]
