export default [
  {
    path: 'test',
    name: 'test',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'test.list',
        meta: {
          permission: ['Test List', 'Test List Owner'],
        },
        component: () => import('@/views/dashboard/generated/test/list.vue'),
      },
      {
        path: 'create',
        name: 'test.create',
        meta: {
          permission: 'Create Test',
        },
        component: () => import('@/views/dashboard/generated/test/create.vue'),
      },
      {
        path: 'trashed',
        name: 'test.trashed',
        meta: {
          permission: ['Show Test Trash', 'Show Test Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/test/trashed.vue'),
      },
    ],
  },
]
