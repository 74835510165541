export default [
  {
    path: 'cimportdecision',
    name: 'cimportdecision',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'cimportdecision.list',
        meta: {
          permission: ['Cimportdecision List', 'Cimportdecision List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/cimportdecision/list.vue'),
      },
      {
        path: 'create',
        name: 'cimportdecision.create',
        meta: {
          permission: 'Create Cimportdecision',
        },
        component: () => import('@/views/dashboard/gtrans/cimportdecision/create.vue'),
      },
      {
        path: 'edit/:cimportdecision',
        name: 'cimportdecision.edit',
        meta: {
          permission: ['Update Cimportdecision', 'Update Cimportdecision Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/cimportdecision/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'cimportdecision.trashed',
        meta: {
          permission: ['Show Cimportdecision Trash', 'Show Cimportdecision Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/cimportdecision/trashed.vue'),
      },
    ],
  },
]
