export default [
  {
    path: 'videoPublictransport',
    name: 'videoPublictransport',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'videoPublictransport.list',
        meta: {
          permission: ['VideoPublictransport List', 'VideoPublictransport List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videoPublictransport/list.vue'),
      },
      {
        path: 'create',
        name: 'videoPublictransport.create',
        meta: {
          permission: 'Create VideoPublictransport',
        },
        component: () => import('@/views/dashboard/gtrans/videoPublictransport/create.vue'),
      },
      {
        path: 'edit/:videoPublictransport',
        name: 'videoPublictransport.edit',
        meta: {
          permission: ['Update VideoPublictransport', 'Update VideoPublictransport Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videoPublictransport/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'videoPublictransport.trashed',
        meta: {
          permission: ['Show VideoPublictransport Trash', 'Show VideoPublictransport Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/videoPublictransport/trashed.vue'),
      },
    ],
  },
]
