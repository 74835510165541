export default [
  {
    path: 'ticon',
    name: 'ticon',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'ticon.list',
        meta: {
          permission: ['Ticon List', 'Ticon List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/ticon/list.vue'),
      },
      {
        path: 'create',
        name: 'ticon.create',
        meta: {
          permission: 'Create Ticon',
        },
        component: () => import('@/views/dashboard/gtrans/ticon/create.vue'),
      },
      {
        path: 'edit/:ticon',
        name: 'ticon.edit',
        meta: {
          permission: ['Update Ticon', 'Update Ticon Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/ticon/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'ticon.trashed',
        meta: {
          permission: ['Show Ticon Trash', 'Show Ticon Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/ticon/trashed.vue'),
      },
    ],
  },
]
