export default [
  {
    path: 'first',
    name: 'first',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'first.list',
        meta: {
          permission: ['First List', 'First List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/first/list.vue'),
      },
      {
        path: 'create',
        name: 'first.create',
        meta: {
          permission: 'Create First',
        },
        component: () => import('@/views/dashboard/gtrans/first/create.vue'),
      },
      {
        path: 'edit/:first',
        name: 'first.edit',
        meta: {
          permission: ['Update First', 'Update First Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/first/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'first.trashed',
        meta: {
          permission: ['Show First Trash', 'Show First Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/first/trashed.vue'),
      },
    ],
  },
]
