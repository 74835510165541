export default [
  {
    path: 'tnewcimport',
    name: 'tnewcimport',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'tnewcimport.list',
        meta: {
          permission: ['Tnewcimport List', 'Tnewcimport List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewcimport/list.vue'),
      },
      {
        path: 'create',
        name: 'tnewcimport.create',
        meta: {
          permission: 'Create Tnewcimport',
        },
        component: () => import('@/views/dashboard/gtrans/tnewcimport/create.vue'),
      },
      {
        path: 'edit/:tnewcimport',
        name: 'tnewcimport.edit',
        meta: {
          permission: ['Update Tnewcimport', 'Update Tnewcimport Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewcimport/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'tnewcimport.trashed',
        meta: {
          permission: ['Show Tnewcimport Trash', 'Show Tnewcimport Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/tnewcimport/trashed.vue'),
      },
    ],
  },
]
