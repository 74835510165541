export default [
  {
    path: 'fundd',
    name: 'fundd',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'fundd.list',
        meta: {
          permission: ['Fundd List', 'Fundd List Owner'],
        },
        component: () => import('@/views/dashboard/generated/fundd/list.vue'),
      },
      {
        path: 'create',
        name: 'fundd.create',
        meta: {
          permission: 'Create Fundd',
        },
        component: () => import('@/views/dashboard/generated/fundd/create.vue'),
      },
      {
        path: 'edit/:fundd',
        name: 'fundd.edit',
        meta: {
          permission: ['Update Fundd', 'Update Fundd Owner'],
        },
        component: () => import('@/views/dashboard/generated/fundd/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'fundd.trashed',
        meta: {
          permission: ['Show Fundd Trash', 'Show Fundd Trash Owner'],
        },
        component: () => import('@/views/dashboard/generated/fundd/trashed.vue'),
      },
    ],
  },
]
