const mutations = {
  GET_USER_DATA(state, value) {
    state.user = value
  },
  GET_USERS_DATA(state, value) {
    state.users = value
  },
  GET_TRASHED_USERS_DATA(state, value) {
    state.trashed_users = value
  },
}
export default mutations
