export default [
  {
    path: 'halabicon',
    name: 'halabicon',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'halabicon.list',
        meta: {
          permission: ['Halabicon List', 'Halabicon List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/halabicon/list.vue'),
      },
      {
        path: 'create',
        name: 'halabicon.create',
        meta: {
          permission: 'Create Halabicon',
        },
        component: () => import('@/views/dashboard/gtrans/halabicon/create.vue'),
      },
      {
        path: 'edit/:halabicon',
        name: 'halabicon.edit',
        meta: {
          permission: ['Update Halabicon', 'Update Halabicon Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/halabicon/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'halabicon.trashed',
        meta: {
          permission: ['Show Halabicon Trash', 'Show Halabicon Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/halabicon/trashed.vue'),
      },
    ],
  },
]
