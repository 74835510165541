export default [
  {
    path: 'backgroundCertificate',
    name: 'backgroundCertificate',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'backgroundCertificate.list',
        meta: {
          permission: ['BackgroundCertificate List', 'BackgroundCertificate List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/backgroundCertificate/list.vue'),
      },
      {
        path: 'create',
        name: 'backgroundCertificate.create',
        meta: {
          permission: 'Create BackgroundCertificate',
        },
        component: () => import('@/views/dashboard/gtrans/backgroundCertificate/create.vue'),
      },
      {
        path: 'edit/:backgroundCertificate',
        name: 'backgroundCertificate.edit',
        meta: {
          permission: ['Update BackgroundCertificate', 'Update BackgroundCertificate Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/backgroundCertificate/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'backgroundCertificate.trashed',
        meta: {
          permission: ['Show BackgroundCertificate Trash', 'Show BackgroundCertificate Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/backgroundCertificate/trashed.vue'),
      },
    ],
  },
]
