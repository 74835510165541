export default [
  {
    path: 'sprice',
    name: 'sprice',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'sprice.list',
        meta: {
          permission: ['Sprice List', 'Sprice List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/sprice/list.vue'),
      },
      {
        path: 'create',
        name: 'sprice.create',
        meta: {
          permission: 'Create Sprice',
        },
        component: () => import('@/views/dashboard/gtrans/sprice/create.vue'),
      },
      {
        path: 'edit/:sprice',
        name: 'sprice.edit',
        meta: {
          permission: ['Update Sprice', 'Update Sprice Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/sprice/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'sprice.trashed',
        meta: {
          permission: ['Show Sprice Trash', 'Show Sprice Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/sprice/trashed.vue'),
      },
    ],
  },
]
