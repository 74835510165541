export default [
  {
    path: 'medical_test',
    name: 'medical_test',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'medicalTest.list',
        meta: {
          permission: ['Medical_test List', 'Medical_test List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/medical_test/list.vue'),
      },
      {
        path: 'create',
        name: 'medicalTest.create',
        meta: {
          permission: 'Create Medical_test',
        },
        component: () => import('@/views/dashboard/gtrans/medical_test/create.vue'),
      },
      {
        path: 'edit/:medicalTest',
        name: 'medicalTest.edit',
        meta: {
          permission: ['Update Medical_test', 'Update Medical_test Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/medical_test/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'medicalTest.trashed',
        meta: {
          permission: ['Show Medical_test Trash', 'Show Medical_test Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/medical_test/trashed.vue'),
      },
    ],
  },
]
