export default [
  {
    path: 'haremmquestion',
    name: 'haremmquestion',
    component: { render: h => h('router-view') },
    children: [
      {
        path: 'list',
        name: 'haremmquestion.list',
        meta: {
          permission: ['Haremmquestion List', 'Haremmquestion List Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/haremmquestion/list.vue'),
      },
      {
        path: 'create',
        name: 'haremmquestion.create',
        meta: {
          permission: 'Create Haremmquestion',
        },
        component: () => import('@/views/dashboard/gtrans/haremmquestion/create.vue'),
      },
      {
        path: 'edit/:haremmquestion',
        name: 'haremmquestion.edit',
        meta: {
          permission: ['Update Haremmquestion', 'Update Haremmquestion Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/haremmquestion/edit.vue'),
      },
      {
        path: 'trashed',
        name: 'haremmquestion.trashed',
        meta: {
          permission: ['Show Haremmquestion Trash', 'Show Haremmquestion Trash Owner'],
        },
        component: () => import('@/views/dashboard/gtrans/haremmquestion/trashed.vue'),
      },
    ],
  },
]
